// https://api-debts-539174983798.asia-southeast1.run.app // ตัวเทส
// https://api-debts-1024031113167.asia-southeast1.run.app // ตัวจริง

const APIDEPT = process.env.REACT_APP_DEPT;

export const getchqtran = `${APIDEPT}/getchqtran`;   //เส้นเดิม
export const results = `${APIDEPT}/api-dets/results`;  //เส้นใหม่โหลดข้อมูลน้า
export const exdept = `${APIDEPT}/api-dets/exdept`;   //เส้นใหม่
export const outsidetoceo = `${APIDEPT}/api-dets/outsidetoceo`   //ส่งเคสหาเฮีย
export const ceo_accept = `${APIDEPT}/api-dets/ceo_accept`   //เฮียรับงาน
export const edit_ceo_accept = `${APIDEPT}/api-dets/edit_ceo_accept`   //เฮียแก้ไขหลังจากอนุมัติ
export const ceo_approve = `${APIDEPT}/api-dets/ceo_approve`   //เฮียอนุมัติ
export const results_contno = `${APIDEPT}/api-dets/results_contno`   //ดึงสัญญาหน้าเฮีย
export const countcase = `${APIDEPT}/api-dets/countcase` //นับจำนวนเคสทุกสัญญา

export const detailPaymen = `${APIDEPT}/api-dets/detail-payment`;  //เส้นใหม่ ตารางการผ่อน
export const getexpreal = `${APIDEPT}/api-dets/get-expreal`;  //เส้นใหม่ 
export const updateexpreal = `${APIDEPT}/api-dets/update-expreal`;  //เส้นใหม่ อัพเดตตาราง expreal
export const getcts_detail = `${APIDEPT}/api-dets/teller`;
// export const report_all = `${APIDEPT}/api-dets/teller`;  // 
export const get_report = `${APIDEPT}/api-dets/get-report`; // เส้นใหม่ ดึงรายงาย
export const insert_mongodb = `${APIDEPT}/api-dets/insert_mongodb`; // เส้นใหม่ ดึงรายงาย
export const search_contno = `${APIDEPT}/api-dets/search-contno` // เส้นใหม่ ข้อมูลตาม contno name1 regno



// export const getchqtran = `http://localhost:8080/getchqtran`   //เส้นเดิม
// export const results = `http://localhost:8080/api-dets/results`   //เส้นใหม่โหลดข้อมูลน้า
// export const exdept = `http://localhost:8080/api-dets/exdept`   //เส้นใหม่การ์ดลูกหนี้ใหม่
// export const outsidetoceo = `http://localhost:8080/api-dets/outsidetoceo`   //ส่งเคสหาเฮีย
// export const ceo_accept = `http://localhost:8080/api-dets/ceo_accept`   //เฮียรับงาน
// export const edit_ceo_accept = `http://localhost:8080/api-dets/edit_ceo_accept`   //เฮียแก้ไขหลังจากอนุมัติ
// export const ceo_approve = `http://localhost:8080/api-dets/ceo_approve`   //เฮียอนุมัติ
// export const results_contno = `http://localhost:8080/api-dets/results_contno`   //ดึงสัญญาหน้าเฮีย
// export const countcase = `http://localhost:8080/api-dets/countcase`   //นับจำนวนเคสทุกสัญญา


// export const detailPaymen = `http://localhost:8080/api-dets/detail-payment`;  //เส้นใหม่ ตารางการผ่อน
// export const getexpreal = `http://localhost:8080/api-dets/get-expreal`;  //เส้นใหม่ 
// export const updateexpreal = `http://localhost:8080/api-dets/update-expreal`;  //เส้นใหม่ อัพเดตตาราง expreal
// export const getcts_detail = `http://localhost:8080/api-dets/teller`
// export const report_all = `http://localhost:8080/api-dets/report_all`
// export const get_report = `http://localhost:8080/api-dets/get-report` // เส้นใหม่ ดึงรายงาย
// export const insert_mongodb = `http://localhost:8080/api-dets/insert_mongodb` // เส้นใหม่ ดึงรายงาย
// export const search_contno = `http://localhost:8080/api-dets/search-contno` // เส้นใหม่ ข้อมูลตาม contno name1 regno
export const create_customer = `https://offercase-car-i67f6gaaqa-as.a.run.app/multi-car/create-customer` // สร้าง customer



// export const testupload = `https://test-i67f6gaaqa-as.a.run.app/upload`; //อัพบัตรประชาชน
export const login = `https://login-api-i67f6gaaqa-as.a.run.app/login-mongo`
export const genQrCodr = `https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/conref2/`
export const showcausename = `https://garfe-i67f6gaaqa-as.a.run.app/api/showcausename`;
export const loadDetailAunSendPG = `https://pg-api-i67f6gaaqa-as.a.run.app/api/load-detail-aunsendpg`;
export const loadMainBoss = `https://api-manager-i67f6gaaqa-as.a.run.app/boss/load-main-boss`;  //หน้าเฮีย
export const mainexpeditethedebt = `https://api-manager-i67f6gaaqa-as.a.run.app/midle/main-expeditethedebt`;   //การ์ดลูกหนี้
export const QRCODE_URL = "https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans"; //gen QRCode
export const getImagess = `https://get-image-i67f6gaaqa-as.a.run.app/ApimgGet/GetImagess`; // get โชว์รูปภาพ
