import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Main from '../../pages/Main';
import MainOperator from "../../pages/OCperators/operators/MainOperators";
import MainCollector from "../../pages/OCperators/collectors/MainCollector";
import MainNewPage_Operators from "../../pages/OCperators/operators/newpage_operators/MainNewPage_Operators";
import Cars from "../../pages/Cars/Cars";
import MainFollow from "../../pages/Cars/follow_case/MainFollow";
import MainTabel from '../../pages/Cars/suspensioncase/checkStepCar/MainCheckStepCar';
import Cars_Expedite from "../../pages/Cars_Expedite/Cars_Expedite";
import MainNew from "../../pages/Receive_Installments/MainNew";
import MainPay from "../../pages/Pay/MainPay";
import Main_Call_Team from "../../pages/Call_Team_Status_Audit_Report/Main_Call_Team";
import "./index.css"
import MainSend_Outside from '../../pages/sendoutside_depts/MainSend_Outside';
import MainOuside from '../../pages/info_outside_ceo/MainOuside';
import MainCheckInfoOutside from '../../pages/check_info_outside_mk/Main_check_info_outside';
import Main_check_info_inspector from '../../pages/check_info_inspector_doc/Main_check_info_inspector';
import MainReport from '../../pages/report/MainReport';
import MainSearch from '../../pages/Search/MainSearch';


export default function Contents() {
    const [navVisible] = useState(false);
    const token = localStorage.getItem("token");

    return (
        <>
            <Routes>
                <Route path="" element={token ? <div className={!navVisible ? "pages" : "pages page-with-navbar"}> <Main /></div> : <Navigate to="/" />} />
                <Route path="/operator" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><MainOperator /></div>} />
                <Route path="/collector" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><MainCollector /></div>} />
                <Route path="/new_page_operator" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><MainNewPage_Operators /></div>} />
                <Route path="/car" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><Cars /></h1></div>} />
                <Route path="/follow_case" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainFollow /></h1></div>} />
                <Route path="/suspensioncase" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainTabel /></h1></div>} />
                <Route path="/search" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><MainSearch /></div>} />
                <Route path="/cars_expedite" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><Cars_Expedite /></div>} />
                <Route path="/contno_detaile" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainNew /></h1></div>} />
                <Route path="/pay" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainPay /></h1></div>} />
                <Route path="/send_outside" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainSend_Outside /></h1></div>} />
                <Route path="/outside_ceo" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainOuside /></h1></div>} />
                <Route path="/outside_mk" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainCheckInfoOutside /></h1></div>} />
                <Route path="/outside_checker" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><Main_check_info_inspector /></h1></div>} />
                <Route path="/call_team_status" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><Main_Call_Team /></h1></div>} />
                <Route path="/report_all" element={<div className={!navVisible ? "pages" : "pages page-with-navbar"}><h1><MainReport /></h1></div>} />
            </Routes>
        </>
    );
}

