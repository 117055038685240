import { Col, Form, Modal, Row, Space, Button, Divider, notification, Upload, message, Card, Image } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from '@ant-design/icons';
import Compressor from 'compressorjs';
import { getImagess } from '../../mid/all-api';

function SendCase_MK({ open, close, data, dataSendtoCEO, customerId, status, onChangeStatused, handleCloseModal }) {
    const [form] = Form.useForm()
    const user = localStorage.getItem("username");
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const addImages = localStorage.getItem('addImage')
    const dataAddImages = JSON.parse(addImages)

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerCar, setFileListCustomerCar] = useState([]);
    const [fileListCustomerLand, setFileListCustomerLand] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListCustomerDeed, setFileListCustomerDeed] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
    const [fileListCustomerStatementImage, setFileListCustomerStatementImage] = useState([]);
    const [fileListCustomerStatementPDF, setFileListCustomerStatementPDF] = useState([]);
    const [fileListCustomerCareer, setFileListCustomerCareer] = useState([]);
    const [fileListCustomerDocument1, setFileListCustomerDocument1] = useState([]);

    const [dataAddImage, setDataAddImage] = useState([]) // โชว์รูป
    const [fileListCustomerKey, setFileListCustomerKey] = useState([]);
    const [fileListCustomerMKandHome, setFileListCustomerMKandHome] = useState([]);
    const [fileListCustomerMKandCustomer, setFileListCustomerMKandCustomer] = useState([]);
    const [fileListCustomerOther, setFileListCustomerOther] = useState([]);

    const [allImg, setAllImg] = useState([]);
    const [fileList, setFileList] = useState([]) // รูปบัตรประชาชน

    const [selectProduct, setSelectProduct] = useState(1) // 1 รถ , 2 ที่ดิน
    const [selectTypeCar, setSelectTypeCar] = useState(1) // 1 รถ , 2 ที่ดิน
    const newFormData = new FormData();
    const [dataNote, setDataNote] = useState({
        noteBy: user,
        status: "1",
        noteDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    })

    // console.log("allImg", allImg)
    // console.log("fileListCustomer", fileListCustomer)
    // console.log("dataAddImage", dataAddImage)
    console.log("dataSendtoCEO", dataSendtoCEO)
    console.log("customerId", customerId)

    useEffect(() => {
        if (dataAddImages?.length > 0) {
            setDataAddImage(allImg)
        }
    }, [allImg])

    const addImg = async (resultImg) => {
        //console.log("resultImg =", resultImg)
        if (allImg?.ts1?.length > 0) {
            await logFormData(allImg.ts1, 1, resultImg)
        }
        if (allImg?.ts2?.length > 0) {
            await logFormData(allImg.ts2, 2, resultImg)
        }
        if (allImg?.ts3?.length > 0) {
            await logFormData(allImg.ts3, 3, resultImg)
        }
        if (allImg?.ts4?.length > 0) {
            await logFormData(allImg.ts4, 4, resultImg)
        }
        // if (allImg?.ts5?.length > 0) {
        //     await logFormData(allImg.ts5, 5, resultImg)
        // }
        if (allImg?.ts6?.length > 0) {
            await logFormData(allImg.ts6, 6, resultImg)
        }
        if (allImg?.ts7?.length > 0) {
            await logFormData(allImg.ts7, 7, resultImg)
        }
        if (allImg?.ts8?.length > 0) {
            await logFormData(allImg.ts8, 8, resultImg)
        }
        if (allImg?.ts9?.length > 0) {
            await logFormData(allImg.ts9, 9, resultImg)
        }
        if (allImg?.ts10?.length > 0) {
            await logFormData(allImg.ts10, 10, resultImg)
        }
        if (allImg?.ts11?.length > 0) {
            await logFormData(allImg.ts11, 11, resultImg)
        }
        if (allImg?.ts12?.length > 0) {
            await logFormData(allImg.ts12, 12, resultImg)
        }
    }

    const logFormData = async (ts, type, resultImg) => {
        for (const ez of ts) {
            var image = {}
            if (type !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }
                    const compressedFile = await new Promise((resolve) => {
                        new Compressor(ez.originFileObj, {
                            quality: quality,
                            success: (result) => {
                                resolve(result);
                            },
                        });
                    });
                    let filename = ez.name
                    let image64 = await getBase64(compressedFile)
                    image.filename = filename
                    image.image64 = image64
                    image.type = type
                    resultImg.push(image)
                }
            } else {
                let filename = ez.name
                let image64 = await getBase64(ez.originFileObj)
                image.filename = filename
                image.image64 = image64
                image.type = type
                resultImg.push(image)
            }
        }
        return resultImg
    };

    /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
    // const handleScanIdCard = async (value) => {
    //     const imgId = { image: value }
    //     setLoading(true)
    //     const headers = { 'Content-Type': 'application/json' }
    //     await axios.post(testupload, imgId, { headers: headers })
    //         .then(res => {
    //             //console.log("img", res.data)
    //             if (res.data) {
    //                 //setDataPost(res.data[0])
    //                 //alert('สามารถอ่านได้')
    //                 // splitAddress(res.data[0])
    //                 loadDataOne(res.data[0])
    //                 setLoading(false)
    //             }
    //             else {
    //                 alert('ไม่สามารถอ่านบัตรได้')
    //                 //message.info('ไม่สามารถอ่านได้')
    //                 setDataPost({})
    //                 setDataAddress({})
    //                 setDataPhone({})
    //                 setLoading(false)
    //             }
    //         })
    //         .catch((err) => alert('ไม่สามารถอ่านบัตรได้'))
    //     setLoading(false)
    // }

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel = () => {
        setPreviewVisible(false);
    }

    async function convertFileToBlob(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const blob = new Blob([reader.result], { type: file.type });
                resolve(blob);
            };
            reader.onerror = reject;

            reader.readAsArrayBuffer(file.originFileObj);
        });
    }

    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = async (event) => {
        if (event.length > 0) {
            var file = event[0].originFileObj
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
            // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
            if (file) {
                if (validImageTypes.includes(file.type)) {
                    const reader = new FileReader(); // สร้าง FileReader object
                    reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                        const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                        //console.log('Base64 Data:', base64Data);
                        //setFileList({ image: base64Data })
                        // handleScanIdCard(base64Data)
                    }
                    reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
                }
            }
        }
    }
    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    const handleChangeCustomer = async ({ fileList }) => { // บัตรประชาชน 4
        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }
        // console.log("fileList", fileList)
        setFileListCustomer(fileList);
        setLoading(false)
    };
    ///////////////// อัปโหลดรูปภาพในส่วนของรถ //////////////////////////////
    const handleChangeCustomerCar = async ({ fileList }) => { //รูปรถ 1
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCar(fileList);
        setLoading(false)
    };
    // เพิ่มใหม่
    const handleChangeCustomerCareer = async ({ fileList }) => { // ประวัติผู้กู้/ ทะเบียน / อาชีพ 6
        for (const removedFile of fileListCustomerCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCareer(fileList);
        setLoading(false)
    };
    const handleChangeCustomerKey = async ({ fileList }) => { // กุญแจ 20 // รอ type ใหม่
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 0)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 20));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerKey(fileList);
        setLoading(false)
    };
    const handleChangeCustomerMKandHome = async ({ fileList }) => { // การตลาด + บ้าน 21 // รอ type ใหม่
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 0)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 21));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerMKandHome(fileList);
        setLoading(false)
    };
    const handleChangeCustomerMKandCustomer = async ({ fileList }) => { // การตลาด + ผู้กู้ 22// รอ type ใหม่
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 0)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 22));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerMKandCustomer(fileList);
        setLoading(false)
    };
    const handleChangeCustomerOther = async ({ fileList }) => { // รูปอื่น ๆ 23// รอ type ใหม่
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 23));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerOther(fileList);
        setLoading(false)
    };
    /////////อ่านเล่มรถ/////////// 
    const handleChangeCustomerDocument = async ({ fileList }) => { // หน้าคู่มือ 2
        for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
                setAllImg(updatedAllImg);
            }
        }
        console.log("fileList book", fileList)
        setFileListCustomerDocument(fileList);
        var imageListCustomerDocument = []
        const formData = new FormData()
        for (const file of fileList) {
            const blob = await convertFileToBlob(file);
            formData.append('image', blob, file.name);
            formData.append('productTypeCar', selectTypeCar.productTypeCar);
        }

        setFileList(formData)
        setFileListCustomerDocument1({ ...fileListCustomerDocument1, imageListCustomerDocument });
        setLoading(false)
    };
    ////////////////////////  รูปภาพส่วนที่ดิน ///////////////////////////////
    const handleChangeCustomerLand = async ({ fileList }) => { // รูปที่ดิน 1
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts1: fileList });
        }
        else {
            for (const removedFile of fileListCustomerLand.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerLand(fileList);
        setLoading(false)
    };


    // ไม่ได้ใช้
    const handleChangeCustomerDeed = async ({ fileList }) => { // โฉนดที่ดิน 3
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts3: fileList });
        } else {
            for (const removedFile of fileListCustomerDeed.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerDeed(fileList);
        setLoading(false)
    };
    const handleChangeCustomerLeafDivider = async ({ fileList }) => { // ใบแบ่งใบต่อ 10
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts10: fileList });
        } else {
            for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerLeafDivider(fileList);
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => { //ระวาง 11 
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts11: fileList });
        } else {
            for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerBurden(fileList);
        setLoading(false)
    };
    const handleChangeCustomerStatementImage = async ({ fileList }) => { // 8 สเตทเมน
        for (const removedFile of fileListCustomerStatementImage.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementImage(fileList);
        setLoading(false)
    };
    const handleChangeCustomerStatementPDF = async ({ fileList }) => { //สเตทเมน PDF 7
        for (const removedFile of fileListCustomerStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementPDF(fileList);
        setLoading(false)
    };



    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
        close(false);
    };

    const handleGoBlack = () => {
        close(false);
    }

    const handleChangeNote = (e) => {
        setDataNote({ ...dataNote, note: e })
    }
    const successSend = (placement) => {
        api.success({
            message: <b>ส่งเคส {dataSendtoCEO?.CONTNO} สำเร็จ</b>,
            placement,
        });
    }
    const errorSend = (placement) => {
        api.error({
            message: <b>ส่งเคส {dataSendtoCEO?.CONTNO} ไม่สำเร็จ</b>,
            placement,
        });
    }
    const errorCustomer = (placement) => {
        api.error({
            message: <b style={{ color: 'red' }}>ไม่มีเลข customer_Id</b>,
            placement,
        });
    }

    const handleUploadImage = async () => {
        console.log("allImg", allImg);
        if (allImg?.length > 0) {
            await imgSend(allImg)
        }

    }

    const imgSend = async (allImg) => {
        console.log("allImg11", allImg);
        var status;
        newFormData.append('idcus', customerId ? customerId : errorCustomer("top"));
        //newFormData.append('productTypeId', "1");
        newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

        for (const file of allImg) {
            var image = {}
            newFormData.append('file', file.imageCompressedFile);
            newFormData.append("type", file.type);
            image.type = file.type
            // resultImage.push(image)
        }
        console.log("newFormData", Array.from(newFormData.entries()));

        // // http://localhost:8080/Apimg/upload-outside-img
        // await axios
        //     .post(` http://localhost:8080/Apimg/upload-outside-img`, newFormData)
        //     .then(async (resReq) => {
        //         if (resReq.data) {
        //             onChangeStatused({ ...dataSendtoCEO, approvalStatus: 28 }, status);
        //             if (resReq.status === 200) {
        //                 successSend("top")
        //                 setTimeout(() => {
        //                     handleCloseModal()
        //                 }, 2000)
        //             }
        //         }
        //     })
        //     .catch((err) => {
        //         errorSend("top")
        //         console.log(err)
        //         setLoading(false);
        //     });


        // const handleSubmit = async () => {
        //     // setLoading(true);

        //     localStorage.setItem('img', JSON.stringify(allImg));
        //     const param = {
        //         BALANCE: dataSendtoCEO.BALANCE,
        //         CONTNO: dataSendtoCEO?.CONTNO,
        //         DATA_TYPE: dataSendtoCEO.DATA_TYPE,
        //         GRDCOD: dataSendtoCEO.GRDCOD,
        //         LPAYD: dataSendtoCEO.LPAYD ? dayjs(dataSendtoCEO.LPAYD).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
        //         NCARCST: dataSendtoCEO.NCARCST,
        //         SMPAY: dataSendtoCEO.SMPAY,
        //         TOT_UPAY: dataSendtoCEO.TOT_UPAY,
        //         EXP_AMT: dataSendtoCEO.EXP_AMT,
        //         TFDATE: dataSendtoCEO.TFDATE ? dayjs(dataSendtoCEO.TFDATE).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
        //         TOTPRC: dataSendtoCEO.TOTPRC.toString(),
        //         offerBy: user,
        //         proposalBy: dataSendtoCEO?.SALCOD, // แก้กลับเป็น data.SALCOD
        //         reviewedBy: dataSendtoCEO?.CHECKER, // แก้กลับเป็น data.CHECKER
        //         T_NOPAY: dataSendtoCEO.T_NOPAY,
        //         notesOutSidetoCEO: dataNote,
        //     }

        //     console.log("param", param)
        //     // await axios
        //     //     .post(`http://localhost:8070/api-dets/outsidetoceo`, param)
        //     //     .then(async (resReq) => {
        //     //         if (resReq.data) {
        //     //             onChangeStatused({ ...dataSendtoCEO, approvalStatus: 28 }, status);
        //     //             if (resReq.status === 200) {
        //     //                 successSend("top")
        //     //                 setTimeout(() => {
        //     //                     handleCloseModal()
        //     //                 }, 2000)
        //     //             }
        //     //         }
        //     //     })
        //     //     .catch((err) => {
        //     //         errorSend("top")
        //     //         console.log(err)
        //     //         setLoading(false);
        //     //     });
        // }
    }

    return (
        <Card>
            <Modal
                title="ส่งชุดนอก"
                open={open}
                width={'700px'}
                onCancel={handleClose}
                footer={[null]}
            >
                <Form
                    style={{ width: '100%', textAlign: 'center' }}
                    form={form}
                    onFinish={handleUploadImage}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row className='mains' justify={'center'}>
                        <Col>
                            <div>
                                <b>
                                    {dataAddImage?.map((imageData, index) => {
                                        console.log("imageData", imageData)
                                        if (imageData.type === "4") {
                                            return (
                                                <Row justify={'center'}>
                                                    <Image
                                                        width={'200px'}
                                                        key={index}
                                                        src={`${getImagess}/${imageData.image}?token=${token}`}
                                                        alt={`รูปภาพ ${index}`}
                                                    />
                                                </Row>
                                            );
                                        }
                                        return null;
                                    })}
                                    <Form.Item label='บัตรประชาชน'
                                        style={{ textAlign: 'center', width: '300px' }}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomer}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomer}
                                            beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomer?.length >= 1 ? null : (
                                                <div style={{ width: '150px' }}>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text" >upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '150px' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>
                        </Col>
                        <Divider style={{ margin: 3 }} />
                        <Col>

                            <div>
                                <b>
                                    {dataAddImage?.map((imageData, index) => {
                                        console.log("imageData@@@@", imageData)
                                        if (imageData.type === "6") {
                                            return (
                                                <Row justify={'center'}>
                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Row>
                                            );
                                        }
                                        return null;
                                    })}
                                    <Form.Item label='ทะเบียน'
                                        style={{ textAlign: 'center', width: '300px' }}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomerCareer}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerCareer}
                                            beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerCareer?.length >= 5 ? null : (
                                                <div style={{ width: '150px' }}>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>

                        </Col>
                        <Divider style={{ margin: 3 }} />
                        <Col>

                            <div>
                                <b>
                                    {/* {dataAddImage?.map((imageData, index) => {
                                        if (imageData.type === "1") {
                                            return (
                                                <Row justify={'center'}>
                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Row>
                                            );
                                        }
                                        return null;
                                    })} */}
                                    <Form.Item label='รูปรถ'
                                        style={{ textAlign: 'center', width: '300px' }}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomerCar}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerCar}
                                            beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerCar?.length >= 15 ? null : (
                                                <div style={{ width: '150px' }}>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>

                        </Col>
                        <Divider style={{ margin: 3 }} />
                        <Col>

                            <div>
                                <b>
                                    {/* {dataAddImage?.map((imageData, index) => {
                                        if (imageData.type === "18") {
                                            return (
                                                <Row justify={'center'}>
                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Row>
                                            );
                                        }
                                        return null;
                                    })} */}
                                    <Form.Item label='กุญแจ'
                                        style={{ textAlign: 'center', width: '300px' }}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomerKey}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerKey}
                                            beforeUpload={(file) => beforeUpts(file, 20).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerKey?.length >= 5 ? null : (
                                                <div style={{ width: '150px' }}>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>

                        </Col>
                        <Divider style={{ margin: 3 }} />
                        <Col>

                            <div>
                                <b>
                                    {/* {dataAddImage?.map((imageData, index) => {
                                        if (imageData.type === "19") {
                                            return (
                                                <Row justify={'center'}>
                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Row>
                                            );
                                        }
                                        return null;
                                    })} */}
                                    <Form.Item label='รูปการตลาด + บ้านลูกค้า'
                                        style={{ textAlign: 'center', width: '300px' }}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomerMKandHome}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerMKandHome}
                                            beforeUpload={(file) => beforeUpts(file, 21).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerMKandHome?.length >= 5 ? null : (
                                                <div style={{ width: '150px' }}>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>

                        </Col>
                        <Divider style={{ margin: 3 }} />
                        <Col>

                            <div>
                                <b>
                                    {/* {dataAddImage?.map((imageData, index) => {
                                        if (imageData.type === "20") {
                                            return (
                                                <Row justify={'center'}>
                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Row>
                                            );
                                        }
                                        return null;
                                    })} */}
                                    <Form.Item label='รูปการตลาด + ผุ้กู้'
                                        style={{ textAlign: 'center', width: '300px' }}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomerMKandCustomer}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerMKandCustomer}
                                            beforeUpload={(file) => beforeUpts(file, 22).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerMKandCustomer?.length >= 5 ? null : (
                                                <div style={{ width: '150px' }}>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>

                        </Col>
                        <Divider style={{ margin: 3 }} />
                        <Col>

                            <div>
                                <b>
                                    {/* {dataAddImage?.map((imageData, index) => {
                                        if (imageData.type === "12") {
                                            return (
                                                <Row justify={'center'}>
                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Row>
                                            );
                                        }
                                        return null;
                                    })} */}
                                    <Form.Item label='อื่น ๆ'
                                        style={{ textAlign: 'center', width: '300px' }}
                                    >
                                        <Upload
                                            accept="image/*"
                                            multiple={true}
                                            style={{ textAlign: 'center' }}
                                            listType="picture-card"
                                            fileList={fileListCustomerOther}
                                            onPreview={handlePreview}
                                            onChange={handleChangeCustomerOther}
                                            beforeUpload={(file) => beforeUpts(file, 23).then(() => false).catch(() => true)}
                                        >
                                            {fileListCustomerOther?.length >= 15 ? null : (
                                                <div style={{ width: '150px' }}>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>

                        </Col>
                    </Row>
                    <Divider style={{ margin: 3 }} />
                    {/* หมายเหตุ */}
                    <Row justify={'center'}>
                        <Col span={22}>
                            <Form.Item
                                name="note"
                                label=""
                            >
                                <div>
                                    <span><b><u>หมายเหตุ</u></b></span><p />
                                    <TextArea
                                        name="note"
                                        placeholder='กรอกหมายเหตุ'
                                        style={{ color: 'blue' }}
                                        rows={8}
                                        onChange={(e) => {
                                            handleChangeNote(e.target.value);
                                        }}
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider style={{ margin: 3 }} />
                    {/* ปุ่ม */}
                    <Row justify={'center'}>
                        <Space>
                            <Button
                                type="text"
                                onClick={handleGoBlack}
                                style={{
                                    background: 'red',
                                    color: 'white',
                                    height: '40px'
                                }} >
                                <b>ปิด</b>
                            </Button>
                            {
                                loading === false ?
                                    <>
                                        <Button type="text" htmlType="submit" style={{ height: '40px', background: '#389e0d', color: '#ffffff' }} >
                                            <b>บันทึก</b>
                                        </Button>
                                    </> :
                                    <>
                                        <Button type='primary' htmlType="submit" disabled
                                            style={{ height: '40px', color: 'success' }}
                                        >
                                            <b>บันทึก</b>
                                        </Button>
                                    </>
                            }
                        </Space>
                    </Row>
                    <Divider style={{ margin: 3 }} />
                </Form>
            </Modal>
            {contextHolder}
        </Card>

    )
}

export default SendCase_MK