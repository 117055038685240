import { Button, Divider, Modal, Table, Col, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { detailPaymen } from "../../mid/all-api";

function ModalInstallment({ open, close, data, DATA_TYPE }) {
  const [dataInstallment, setDataInstallment] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    const timeout = setTimeout(() => {
      loadInstallment();
    }, 1000);
    return () => clearTimeout(timeout);

  }, [data]);

  const handleClose = () => {
    close(false);
  };

  const loadInstallment = async () => {
    setLoading(true)
    await axios
      .post(detailPaymen, { CONTNO: data?.CONTNO, DATA_TYPE: data?.DATA_TYPE })
      .then((res) => {
        // console.log("res", res.data)
        if (res.data === "ไม่มีเลขสัญญา") {
          setDataInstallment([]);
        } else {
          setDataInstallment(res.data);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));

  };

  const columns = [
    {
      title: "งวดที่",
      dataIndex: "NOPAY",
      // width: "100%",
      align: "center",
      sorter: {
        compare: (a, b) => a.NOPAY - b.NOPAY,
        multiple: 1,
      },
    },
    {
      title: "กำหนดชำระ",
      dataIndex: "DDATE",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div style={{ width: '100%' }}>
            <span>
              {record.DDATE ? dayjs(record.DDATE).format("DD-MM-YYYY") : null}
            </span>
          </div>
        )
      },
    },
    {
      title: "ค่างวด",
      dataIndex: "DAMT",
      width: "110px",
      align: "center",
      render: (text, record) => {
        return (
          <div style={{ width: '100%' }}>
            <span>
              {currencyFormatOne(record.DAMT)}
            </span>
          </div>
        )
      },
    },
    {
      title: "วันที่ชำระ",
      dataIndex: "DATE1",
      align: "center",
      width: "120px",
      render: (text, record) => {
        return (
          <div style={{ width: '100%' }}>
            <span>
              {record.DATE1 ? dayjs(record.DATE1).format("DD-MM-YYYY") : null}
            </span>
          </div>
        )
      },
    },
    {
      title: "ยอดชำระ",
      dataIndex: "PAYMENT",
      // width: "100%",
      align: "center",
      render: (text, record) => <>{currencyFormatOne(record.PAYMENT)}</>,
    },
  ];

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        footer={[null]}
        closeIcon={false}
        width={800}
        height={600}
      >
        <Spin spinning={loading} size="large" tip=" Loading... ">
          <Divider orientation="center">
            <b>ตารางการผ่อน</b>
          </Divider>
          <Table
            columns={columns}
            // dataSource={dataInstallment}
            dataSource={dataInstallment.map((item, index) => ({
              ...item,
              key: `${item.NOPAY}-${index}`, // ใช้การผสมค่าของ NOPAY และ index เพื่อสร้าง key
            }))}
            scroll={{ x: 500, y: 545 }}
          />
          <Col span={24} style={{ textAlign: "right" }}>
            <Space>
              <Button type="primary" danger onClick={handleClose}>
                ปิด
              </Button>
            </Space>
          </Col>
        </Spin>
      </Modal>
    </>
  );
}

export default ModalInstallment;
