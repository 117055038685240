import { Button, Card, Col, Divider, Input, Row, Space, Spin, Table, Tag, notification } from 'antd'
import React from 'react'
import { useState } from 'react';
import dayjs from "dayjs";
import { SearchOutlined, UpOutlined } from "@ant-design/icons";
import axios from 'axios';
import { search_contno } from '../mid/all-api';
import { FaFileInvoiceDollar, FaMinus, FaPlus, FaQrcode, FaSortDown, FaSortUp, FaTasks } from 'react-icons/fa';
import { useEffect } from 'react';

import ModalInstallment from "../check_info_PG_RE/modals/ModalInstallment";
import BillExpedite from "./modals/BillExpedite";
import QRCodeWithContractNo from './modals/QRCodeWithContractNo';

function MainSearch() {

    const [loading, setLoading] = useState(false);
    const [dataSearch, setDataSearch] = useState();
    const [arrayTable, setArrayTable] = useState();
    const [expandedRowsPay, setExpandedRowsPay] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    const [isFloatingVisible, setIsFloatingVisible] = useState(false);
    const [scrollY, setScrollY] = useState(window.innerHeight);
    const [scrollX, setScrollX] = useState(window.innerWidth);
    const [showButton, setShowButton] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [queryContno1, setQueryContno1] = useState(2);

    const [dataInfoCar, setDataInfoCar] = useState(null);
    const [dataSend, setDataSend] = useState({});
    const [dataInstallment, setDataInstallment] = useState();

    const [isModalInstallment, setIsModalInstallment] = useState(false);
    const [isModalInfoBill, setIsModalInfoBill] = useState(false);
    const [isModalQrCode, setModalQrCode] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const bottomPosition =
                document.documentElement.scrollHeight - document.documentElement.clientHeight;
            setShowButton(scrollPosition >= bottomPosition - 50);

        };

        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768);
            setScrollY(window.innerHeight);
            setScrollX(window.innerWidth);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', checkScreenSize);

        // เรียกใช้ฟังก์ชันตอนโหลดครั้งแรก
        handleScroll();
        checkScreenSize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);


    const LoadData = async () => {
        setLoading(true)
        const param = {
            Search: dataSearch
        }
        console.log("param", param);

        await axios.post(search_contno, param)
            .then((res) => {
                if (res.data) {
                    console.log("res.data", res.data);
                    setArrayTable(res.data)
                    setLoading(false)
                } else {
                    SearchNotFound('top')
                    setArrayTable([])
                    console.log("Not Find Data");
                    setLoading(false)

                }
            }).catch((err) => {
                console.log("err", err);

            })

    }

    const SearchNotFound = (placement) => {
        api.error({
            message: <b>ไม่พบข้อมูลที่ค้นหา</b>,
            placement,
        });
    }

    // ช่องกรอก
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        console.log("inputValue", inputValue);
        setDataSearch(inputValue);
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return 0;
        }
    };

    const expandableConfig = queryContno1 === 2 ? {
        expandedRowRender: isMobile
            ? () => null // บนมือถือไม่แสดง expandable row
            : (record) => (
                <div
                    className={`floating-buttons ${isFloatingVisible ? 'visible' : 'hidden'}`}
                >
                    <Space size="small">
                        <Button
                            title="ติดตามค่างวด"
                            onClick={() => {
                                setDataInfoCar(record.CONTNO);
                                setIsModalInfoBill(true);
                                setDataSend(record);
                            }}
                        >
                            <FaFileInvoiceDollar size={20} />
                        </Button>
                        <Button
                            title="QR code"
                            onClick={() => {
                                setDataInfoCar(record.CONTNO);
                                setModalQrCode(true)
                                setDataSend(record);
                            }}
                        >
                            <FaQrcode size={20} />
                        </Button>
                        <Button
                            title="ตารางการผ่อน"
                            onClick={() => {
                                setDataInstallment(record);
                                setIsModalInstallment(true);
                            }}
                        >
                            <FaTasks size={20} />
                        </Button>
                    </Space>
                </div>
            ),
        expandIcon: ({ expanded, onExpand, record }) =>
            record?.flagClose ? null : (
                <>
                    {expanded ? (
                        <FaMinus
                            onClick={(e) => {
                                onExpand(record, e);
                                if (isMobile) setIsFloatingVisible(false);
                            }}
                            style={{
                                cursor: 'pointer',
                                ...(isMobile && { float: 'left', marginRight: '10px' }) // ชิดซ้ายในมือถือ
                            }}
                        />
                    ) : (
                        <FaPlus
                            onClick={(e) => {
                                onExpand(record, e);
                                if (isMobile) setIsFloatingVisible(true);
                            }}
                            style={{
                                cursor: 'pointer',
                                ...(isMobile && { float: 'left', marginRight: '10px' }) // ชิดซ้ายในมือถือ
                            }}
                        />
                    )}
                    {isMobile && expanded && (
                        <div
                            className={`floating-buttons ${isFloatingVisible ? 'visible' : 'hidden'}`}
                        >
                            <Space size="small">
                                <Button
                                    title="ติดตามค่างวด"
                                    onClick={() => {
                                        setDataInfoCar(record.CONTNO);
                                        setIsModalInfoBill(true);
                                        setDataSend(record);
                                    }}
                                >
                                    <FaFileInvoiceDollar size={20} />
                                </Button>
                                <Button
                                    title="QR code"
                                    onClick={() => {
                                        setDataInfoCar(record.CONTNO);
                                        setModalQrCode(true)
                                        setDataSend(record);
                                    }}
                                >
                                    <FaQrcode size={20} />
                                </Button>
                                <Button
                                    title="ตารางการผ่อน"
                                    onClick={() => {
                                        setDataInstallment(record);
                                        setIsModalInstallment(true);
                                    }}
                                >
                                    <FaTasks size={20} />
                                </Button>
                            </Space>
                        </div>
                    )}
                </>
            ),

    }
        :
        null
        ;
    const expandableConfigMoblie = queryContno1 === 2 ? {
        expandedRowRender: null,
        expandIcon: ({ expanded, onExpand, record }) =>
            record?.flagClose ? null : (
                <>
                    {isMobile && (
                        <div
                            className={`floating-buttons ${isMobile ? 'visible' : 'hidden'}`}
                            style={{
                                width: '200px',
                                textAlign: 'center',
                                alignItems: 'center',
                                padding: 5,
                                borderRadius: '12px'
                            }}
                        >
                            <Space size="small">
                                <Button
                                    title="ติดตามค่างวด"
                                    onClick={() => {
                                        setDataInfoCar(record.CONTNO);
                                        setIsModalInfoBill(true);
                                        setDataSend(record);
                                    }}
                                >
                                    <FaFileInvoiceDollar size={20} />
                                </Button>
                                <Button
                                    title="QR code"
                                    onClick={() => {
                                        setDataInfoCar(record.CONTNO);
                                        setModalQrCode(true)
                                        setDataSend(record);
                                    }}
                                >
                                    <FaQrcode size={20} />
                                </Button>
                                <Button
                                    title="ตารางการผ่อน"
                                    onClick={() => {
                                        setDataInstallment(record);
                                        setIsModalInstallment(true);
                                    }}
                                >
                                    <FaTasks size={20} />
                                </Button>
                            </Space>
                        </div>
                    )}
                </>
            ),

    }
        :
        null
        ;

    const paginationConfig = isMobile
        ? {
            position: ["bottomLeft"],
            style: { width: "100%", }
        } // ตั้งค่า pagination สำหรับมือถือ
        : null; // ปิด pagination ถ้าไม่ใช่มือถือ


    const columns = [
        {
            title: "",
            dataIndex: "BILLCOLL",
            key: "BILLCOLL",
            align: "center",
            // render: (text, object, key) => key + 1,
            render: (text, record, index) => {
                return isMobile ? (
                    <p style={{ padding: '20px' }}>-</p>
                ) : (
                    <span>{record?.BILLCOLL1}</span>
                );
            },
            sorter: {
                compare: (a, b) => a.key - b.key,
                multiple: 5,
            },
        },
        {
            title: "รายละเอียด",
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: "center",
            onCell: () => ({
                "key": "ลำดับ",
            }),
            render: (text, record1) => {
                const dataSource = [record1]
                const filteredData = dataSource.filter(record => record.products !== 'land');
                if (record1?.products === 'land') {
                    return null;
                }

                const dataNew = () => {
                    return (
                        <>
                            <div> เลขสัญญา <b>{record1?.CONTNO ? record1?.CONTNO : null}</b> </div>
                            <div>{record1.SNAM} {record1.NAME1} {record1.NAME2}</div>
                            {
                                record1.BAAB || record1.TYPE || record1.MODEL || record1.MANUYR || record1.REGNO ?
                                    <div>
                                        {record1.TYPE ? record1.TYPE + ' ' : null}
                                        {/* {record1.MODEL ? record1.MODEL + ' ' : null} */}
                                        {record1.BAAB ? record1.BAAB + ' ' : null}
                                        {record1.REGNO ? ' เลขทะเบียน' + ' ' + record1.REGNO : null}
                                    </div>
                                    : null
                            }
                        </>
                    )
                }

                return (
                    <div>
                        {dataNew()}
                    </div>
                );
            }
        },
        {
            title: "ที่อยู่",
            dataIndex: "addresses",
            key: "addresses",
            align: "center",
            onCell: () => ({
                "key": "ลำดับ",
            }),
            render: (text, record) => {
                return (
                    <div data-label="ที่อยู่ : " style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        {
                            record?.addresses?.length > 1 ?
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "8px",
                                            height: "100%",
                                            color: 'white',
                                            background: '#6b77ab',
                                            border: "2px solid #302e94f7", // เส้นกรอบรอบข้อมูล
                                            borderRadius: "8px", // เพิ่มมุมโค้งให้เส้นกรอบ
                                            margin: "8px 0", // ระยะห่างระหว่างแต่ละชุดข้อมูล
                                            boxShadow: "0 2px 4px rgba(29, 13, 102, 0.1)", // เพิ่มเงาให้ดูสวยงาม
                                            cursor: "pointer",
                                        }}
                                    // onClick={() => handleToggleExpandPay(record.key)}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    color: 'whtie',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {record?.addresses[0]?.ADDRESS ? ' ' + record?.addresses[0]?.ADDRESS + ' ' : null}
                                                {record?.addresses[0]?.AUMP ? ' ' + record?.addresses[0]?.AUMP + ' ' : null}
                                                {record?.addresses[0]?.TUMB ? ' ' + record?.addresses[0]?.TUMB + ' ' : null}
                                                {record?.addresses[0]?.PROVDES ? ' ' + record?.addresses[0]?.PROVDES + ' ' : null}
                                                {record?.addresses[0]?.ZIP ? ' ' + record?.addresses[0]?.ZIP + ' ' : null}
                                            </span>
                                        </div>

                                        <span style={{ marginLeft: '10px' }}>
                                            {expandedRowsPay.includes(record.key) ? (
                                                <FaSortUp size={20} /> // แสดงลูกศรลงเมื่อขยาย
                                            ) : (
                                                <FaSortDown size={20} /> // แสดงลูกศรขวาเมื่อยุบ
                                            )}
                                        </span>

                                        {record?.addresses?.map((item, i) => {
                                            return (
                                                <React.Fragment key={`data-${record.key}-${i}`}> {/* เพิ่ม key */}
                                                    {
                                                        expandedRowsPay.includes(record.key) && (
                                                            <>
                                                                <Divider style={{ margin: 3 }} />
                                                                <div
                                                                    style={{
                                                                        marginTop: '10px',
                                                                        paddingLeft: '5px',
                                                                        backgroundColor: 'green',
                                                                        color: 'white',
                                                                        borderRadius: "8px",
                                                                        padding: '8px',
                                                                    }}
                                                                >
                                                                    <span><b><u>ลำดับที่ : {i + 1}</u></b></span>
                                                                    <div>
                                                                        {item.ADDRESS ? ' ' + item.ADDRESS + ' ' : null}
                                                                        {item.AUMP ? ' ' + item.AUMP + ' ' : null}
                                                                        {item.TUMB ? ' ' + item.TUMB + ' ' : null}
                                                                        {item.PROVDES ? ' ' + item.PROVDES + ' ' : null}
                                                                        {item.ZIP ? ' ' + item.ZIP + ' ' : null}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <>
                                    {record?.addresses?.map((item, i) => {
                                        return (
                                            <React.Fragment key={`data-${record.key}-${i}`}> {/* เพิ่ม key */}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: "8px",
                                                        height: "100%",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "8px",
                                                        margin: "8px 0",
                                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                                                    }}
                                                >
                                                    <div>
                                                        {item.ADDRESS ? ' ' + item.ADDRESS + ' ' : null}
                                                        {item.AUMP ? ' ' + item.AUMP + ' ' : null}
                                                        {item.TUMB ? ' ' + item.TUMB + ' ' : null}
                                                        {item.PROVDES ? ' ' + item.PROVDES + ' ' : null}
                                                        {item.ZIP ? ' ' + item.ZIP + ' ' : null}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </>
                        }
                    </div >
                );
            }
        },
        {
            title: "การชำระ",
            dataIndex: "Payment",
            key: "Payment",
            align: "center",
            render: (text, record) => (
                <>
                    <div data-label="การชำระ : " style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <div>
                            <span>
                                จ่ายขั้นต่ำ : { }
                            </span>
                            {record.PAYLIMITAMT ? currencyFormatOne(record.PAYLIMITAMT) + ' ' + 'บาท' : '-'}
                        </div>
                        <div>
                            <span>
                                วันที่จ่าย : { }
                            </span>
                            {record.PAYDT ? dayjs(record.PAYDT).format("DD-MM-YYYY") : '-'}
                        </div>
                        <div>
                            <b><u>
                                <span>
                                    ยอดที่จ่าย : { }
                                </span>
                                {record.NETPAY ? currencyFormatOne(record.NETPAY) + ' ' + 'บาท' : '-'}
                            </u></b>
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: "เกรด",
            dataIndex: "GRDCOD",
            key: "GRDCOD",
            align: "center",
            render: (text, record) => {

                return (
                    <div data-label="เกรด : " style={{ textAlign: 'center' }} >
                        {record?.GRDCOD ?
                            <span
                                style={{
                                    // color: 'white',
                                    // background: '#6b77ab',
                                    padding: '12px',
                                    fontSize: '18px',
                                    borderRadius: '5px',
                                }}
                            >
                                <b>
                                    {record?.GRDCOD}
                                </b>
                            </span>
                            : "-"}
                    </div>
                )
            }
        },
        {
            title: "เจ้าของสัญญา",
            dataIndex: "BILLCOLL",
            key: "BILLCOLL",
            align: "center",
            render: (text, record) => {

                return (
                    <div data-label="เจ้าของสัญญา : " style={{ textAlign: 'center' }} >
                        {record?.BILLCOLL ?
                            <span
                                style={{
                                    padding: '12px',
                                    fontSize: '18px',
                                    borderRadius: '5px',
                                }}
                            >
                                <b>
                                    {record?.BILLCOLL}
                                </b >
                            </span>
                            : "-"}
                    </div>
                )
            }
        },
        {
            title: "สถานะการจ่าย",
            dataIndex: "ApprovalStatus",
            key: "ApprovalStatus",
            align: "center",
            render: (text, record) => {
                const netPay = Number(record.NETPAY);
                const payLimitAmt = Number(record.PAYLIMITAMT);
                if (!isNaN(netPay) && !isNaN(payLimitAmt)) {
                    const check = netPay >= payLimitAmt
                    return (
                        <div data-label="สถานะการจ่าย : " style={{ textAlign: 'center' }} >
                            {check ? <Tag style={{ background: 'green', color: 'white', fontSize: '16px', padding: 5 }} > จ่ายแล้ว</Tag > : "-"}
                        </div>
                    )
                }
            }
        },
        {
            title: "สถานะสัญญา",
            dataIndex: "ApprovalStatus",
            key: "ApprovalStatus",
            align: "center",
            render: (text, record) => {
                // console.log("record", record)
                const netPay = Number(record.NETPAY);
                const payLimitAmt = Number(record.PAYLIMITAMT);
                if (!isNaN(netPay) && !isNaN(payLimitAmt)) {
                    const check = netPay >= payLimitAmt
                    return (
                        <>
                            <div data-label="สถานะสัญญา : " style={{ textAlign: 'center' }}>
                                {
                                    record?.flagTellAun ? <Tag style={{ background: "#a09df0", color: 'white', fontSize: '16px', padding: 5 }} >เร่งรัดนอก</Tag>
                                        : record?.flagClose ? <Tag style={{ background: "#e6283beb", color: 'white', fontSize: '16px', padding: 5 }}>เคสปิด</Tag>
                                            : record?.flagOffer ? <Tag style={{ background: "blue", color: 'white', fontSize: '16px', padding: 5 }}>ส่งการตลาด</Tag>
                                                : record?.flagTellAun && record?.flagOffer ? <Tag style={{ background: "blue", color: 'white', fontSize: '16px', padding: 5 }}>ส่งการตลาด</Tag>
                                                    : "-"
                                }
                            </div>
                        </>
                    )
                }
            }
        },
    ]


    return (
        <div>
            <Card style={{ height: '100%', fontSize: '20px', }}      >
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h2>ค้นหาสัญญา</h2>
                </Col>
                <Spin spinning={loading} size="large" tip="Loading..." >
                    <div className="responsive-table">
                        <Row justify={'center'}>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Input
                                    style={{ width: "280px", height: '40px' }}
                                    placeholder="ค้นหาเลขที่สัญญา , ขื่อ , ทะเบียน"
                                    onChange={handleInputChange}
                                    // onKeyDown={handleKeyDown}
                                    name="search"
                                    id="search"
                                />
                            </div>

                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button
                                    style={{ marginRight: "10px", height: '40px', width: "50px" }}
                                    onClick={LoadData}
                                >
                                    <SearchOutlined />
                                </Button>
                            </div>
                        </Row>
                        <Row>
                            <Table
                                key={arrayTable?.length}
                                style={{ marginTop: "5px" }}
                                scroll={{
                                    y: "600px",
                                    // y: calculateTableHeight(),
                                }}
                                expandable={isMobile ? expandableConfigMoblie : expandableConfig}
                                dataSource={arrayTable}
                                columns={columns}
                                pagination={paginationConfig}
                            ></Table>
                        </Row>
                    </div>
                </Spin>
            </Card>
            {contextHolder}

            {
                isModalInfoBill ?
                    <BillExpedite
                        open={isModalInfoBill}
                        close={setIsModalInfoBill}
                        continoBill={dataInfoCar}
                        data={dataSend}
                    // onChangeStatus={handleChangeStatus}
                    // status={selectedValue}
                    />
                    : null}
            {isModalQrCode ?
                <QRCodeWithContractNo
                    open={isModalQrCode}
                    close={setModalQrCode}
                    dataQr={dataInfoCar}
                />
                :
                null
            }
            {
                isModalInstallment ?
                    <ModalInstallment
                        open={isModalInstallment}
                        close={setIsModalInstallment}
                        data={dataInstallment}
                    />
                    : null
            }
        </div>
    )
}

export default MainSearch