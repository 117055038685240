import { Button, Card, Col, DatePicker, Form, Row, Select, Spin, Table, Modal, Tag } from 'antd'
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { get_depts_mongodb, get_report, report_all } from '../mid/all-api';
import { FaDatabase } from 'react-icons/fa';
import DataColumnsCar from '../Cars_Expedite/dataColumn';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { font } from "../../fonts/THSarabunNew-normal";

function MainReport() {
    const { confirm } = Modal;
    const thaiFont = font;
    const [loading, setLoading] = useState(false);
    const [arrayTable, setArrayTable] = useState([]);
    const [user, setUser] = useState("");
    const [dataArr, setDataArr] = useState([]);
    const [dataFilter, setDateFilter] = useState([]);
    const [selectedValueUser, setSelectedValueUser] = React.useState(0);
    const [selectedValueType, setSelectedValueType] = React.useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);
    const dayCurrent = dayjs();
    const dateWithDay11 = dayjs(dayCurrent).date(11);
    const [dateselect, setDateSelect] = useState(dayjs(dateWithDay11).format('YYYY-MM-DD'));

    const [counts, setCounts] = useState([
        { key: 0, value: 0 },
        { key: 1, value: 0 },
        { key: 2, value: 0 },
        { key: 3, value: 0 },
        { key: 4, value: 0 },
    ]);
    const [countsuser, setCountsUser] = useState([
        { key: 0, value: 0 },
        { key: 1, value: 0 },
        { key: 2, value: 0 },
        { key: 3, value: 0 },
        { key: 4, value: 0 },
        { key: 5, value: 0 },
        { key: 6, value: 0 },
    ]);
    // console.log("arrayTable", arrayTable);
    // console.log("dataArr", dataArr);
    // console.log("dataOne", dataOne);
    // console.log("dataTwo", dataTwo);
    // console.log("dataThree", dataThree);
    // console.log("dataEg", dataEg);
    // console.log("dataLoaded", dataLoaded);
    // console.log("counts", counts);
    // console.log("countsuser", countsuser);
    // console.log("user", user);
    // console.log("selectedValueType", selectedValueType);


    const handleChangeDate = (date) => {
        // ตั้งวันที่เป็นวันที่ 11 ของเดือนนั้น
        const dateWithDay11 = dayjs(date).date(11);
        // Log วันที่ 11 ของเดือนนั้น
        setDateSelect(dateWithDay11.format('YYYY-MM-DD'))

    }

    const exportToPDF = async () => {
        if (arrayTable.length > 0) {
            const doc = new jsPDF({
                orientation: "landscape", // ตั้งค่ากระดาษแนวนอน
                unit: "mm", // หน่วย (เช่น mm, pt)
                format: "a4" // ขนาดกระดาษ (A4, Letter, ฯลฯ)
            });
            const result = await testreport(); // ดึงข้อมูลที่เตรียมไว้

            // ฝังฟอนต์ไทยใน PDF
            doc.addFileToVFS("THSarabunNew.ttf", thaiFont);
            doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");

            // ตั้งค่าให้ใช้ฟอนต์ไทย
            doc.setFont("THSarabunNew");
            doc.setFontSize(12);

            doc.text(`รายงานลูกหนี้ครบกำหนดชำระ ทีมตาม ${user || ""}`, doc.internal.pageSize.width / 2, 10, {
                align: "center" // จัดข้อความให้อยู่ตรงกลาง
            });
            // เขียนข้อความภาษาไทย
            const headers_car = ["ชื่อลูกค้า", "สินค้า", "ยอดผ่อน", "ค้างงวด", "เงินค้างค่างวด\nค้างค่าปรับ", "ชำระล่าสุด", "ขาดการติดต่อ(วัน)", "ลูกหนี้คงเหลือ\nชำระค่าปรับ", "ชำระขั้นต่ำ\nทีมตาม", "เกรด"];
            const headers_land = ["ชื่อลูกค้า", "สินค้า", "ยอดผ่อน", "ค้างงวด", "เงินค้างค่างวด\nค้างค่าปรับ", "ชำระล่าสุด", "ขาดการติดต่อ(วัน)", "ลูกหนี้คงเหลือ\nชำระค่าปรับ", "ชำระขั้นต่ำ\nทีมตาม", "เกรด"];
            const headers = selectedValueType === 1 ? headers_land : headers_car;

            const data = arrayTable.map(item =>
                [
                    item?.ARCONTNO + '\n' + item?.SNAM + ' ' + item?.NAME1 + ' ' + item?.NAME2 + '\n' + item?.TELP,
                    item?.TYPE + '\n' + item?.MODEL + '\n' + (item?.DATA_TYPE === "lsfhp" ? item?.STRNO : item?.REGNO),
                    currencyFormatOne(item?.TOTPRC),
                    ' จากงวด ' + item?.KANG + ' \n ' + 'ประมาณ ' + '(' + item?.EXPREAL + ')' + ' งวด',
                    currencyFormatOne(item?.PAYLIMITAMT),
                    dayjs(item?.LPAYD).format('YYYY-MM-DD') + '\n ' + currencyFormatOne(item?.LPAYA),
                    item?.LOSTDAY + ' วัน',
                    currencyFormatOne(item?.KARBAL),
                    currencyFormatOne(item?.PAYLIMITAMT) + ' \n' + item?.BILLCOLL,
                    item?.GRDCOD
                ]);

            // คำนวณความกว้างคอลัมน์
            const totalWidth = doc.internal.pageSize.width - 20; // ความกว้างของหน้า (ลบขอบ 10mm จากทั้งสองข้าง)
            const numberOfColumns = headers.length;
            const columnWidth = totalWidth / numberOfColumns; // ความกว้างคอลัมน์แต่ละอัน
            const firstColumnWidth = 45; // ความกว้างของคอลัมน์แรก
            const thiColumnWidth = 30; // ความกว้างของคอลัมน์แรก
            // สร้างตาราง
            doc.autoTable({
                head: [headers],
                body: data,
                startY: 15, // เริ่มวาดตารางจากตำแหน่ง Y = 40
                styles: {
                    font: "THSarabunNew", // ใช้ฟอนต์ไทยที่เราฝังไว้
                    fontSize: 12 // ขนาดฟอนต์ในตาราง
                },
                headStyles: {
                    fillColor: [100, 149, 237], // สีพื้นหลังหัวตาราง (Cornflower Blue)
                    textColor: [255, 255, 255]  // สีข้อความในหัวตาราง (สีขาว)
                },
                columnStyles: headers.reduce((acc, _, index) => {
                    if (index === 0) {
                        acc[index] = { cellWidth: firstColumnWidth }; // คอลัมน์แรกกว้างเป็นพิเศษ
                    } else if (index === 3) {
                        acc[index] = { cellWidth: thiColumnWidth }; // คอลัมน์ที่ 3 แคบกว่าปกติ
                    } else {
                        acc[index] = { cellWidth: columnWidth }; // คอลัมน์อื่นใช้ค่าปกติ
                    }
                    return acc;
                }, {}),

                didDrawPage: (data) => {
                    // เพิ่มหมายเลขหน้า
                    const pageNumber = doc.internal.getNumberOfPages();
                    doc.setFontSize(12);
                    doc.text(`หน้าที่ ${pageNumber}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
                }
            });
            doc.save("report-th.pdf");
        } else {
            showModalNoti()
        }
    }
    const testreport = async () => {
        const result = [];
        arrayTable?.forEach((e) => {
            let STRREGNO = e?.DATA_TYPE === "lsfhp" ? e?.STRNO : e?.REGNO;

            const data = {}; // สร้างวัตถุใหม่ในแต่ละรอบ
            data.ARCONTNO = e.ARCONTNO + '\n' + e?.SNAM + ' ' + e?.NAME1 + ' ' + e?.NAME2 + '\n' + e?.TELP;
            data.TYPE = (e?.TYPE || 'N/A') + ' ' + (e?.MODEL || '') + ' ' + STRREGNO;
            data.TOTPRCL = currencyFormatOne(e?.TOTPRCL);
            data.KANG = e?.KANG + ' ' + e?.EXPREAL;
            data.PAYLIMITAMT = currencyFormatOne(e?.PAYLIMITAMT);
            data.LPAYD = dayjs(e?.LPAYD).format('YYYY-MM-DD') + ' ' + currencyFormatOne(e?.LPAYA);
            data.LOSTDAY = e?.LOSTDAY;
            data.KARBAL = currencyFormatOne(e?.KARBAL);
            data.PAYLIMITAMT = currencyFormatOne(e?.PAYLIMITAMT) + ' ' + e?.BILLCOLL;
            data.GRDCOD = e?.GRDCOD;
            result.push(data);
        });

        // console.log("result", result); // แสดงผลข้อมูลทั้งหมด
        return result; // ส่งคืนอาร์เรย์ข้อมูลทั้งหมด
    };

    // const exportToExcel = async () => {
    //     // const user = localStorage.getItem("username") || "ไม่ระบุชื่อผู้ใช้งาน";
    //     const result = await testexcel(); // ดึงข้อมูลที่เตรียมไว้
    //     const workbook = new ExcelJS.Workbook();
    //     const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
    //         const worksheet = workbook.addWorksheet(sheetName);
    //         worksheet.mergeCells("A1:F1"); // รวมเซลล์
    //         const titleCell = worksheet.getCell("A1");
    //         titleCell.value = `รายงานลูกหนี้`;
    //         titleCell.alignment = { horizontal: "center", vertical: "middle" };
    //         titleCell.font = { bold: true, size: 16 };

    //         worksheet.addRow(headers_thai).font = { bold: true };
    //         worksheet.columns = [
    //             { key: "เลขสัญญา", width: 15 },
    //             { key: "ชื่อลูกค้า", width: 25 },
    //             { key: "สินค้า", width: 30 },
    //             { key: "ที่อยู่", width: 35 },
    //             { key: "ทะเบียน", width: 10 },
    //             { key: "เบอร์โทร", width: 10 },
    //             { key: "เกรด", width: 8, horizontal: "center", vertical: "middle" },
    //             { key: "สถานะ", width: 13, horizontal: "center", vertical: "middle" },
    //         ];
    //         dataSource.forEach((row) => {
    //             const rowData = headers_colums_name.map((column) => row[column]);
    //             worksheet.addRow(rowData);
    //         });
    //     };

    //     const headers_thai = ["เลขสัญญา", "ชื่อลูกค้า", "สินค้า", "ที่อยู่", "เบอร์โทร", selectedValueType === 1 ? "โฉนด" : "ทะเบียน", "เกรด", "สถานะ"];
    //     const headers_colums_name = ["ARCONTNO", "FullName", "TYPE", "addresses", "TELP", selectedValueType === 1 ? "STRNO" : "REGNO", "GRDCOD", "Status"];

    //     let results = result.filter((item) => (item.Status === "จ่ายแล้ว"))
    //     let results1 = result.filter((item) => (item.Status === "จ่ายยังไม่ถึงขั้นต่ำ"))
    //     let results2 = result.filter((item) => (item.Status === "ยังไม่จ่าย"))

    //     if (results?.length > 0) {

    //         addSheet("จ่ายแล้ว", results, headers_colums_name, headers_thai);
    //     }
    //     if (results1?.length > 0) {

    //         addSheet("จ่ายยังไม่ถึงขั้นต่ำ", results1, headers_colums_name, headers_thai);
    //     }
    //     if (results2?.length > 0) {
    //         addSheet("ยังไม่จ่าย", results2, headers_colums_name, headers_thai);
    //     }

    //     workbook.xlsx.writeBuffer().then((buffer) => {
    //         const blob = new Blob([buffer], {
    //             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //         });
    //         const url = window.URL.createObjectURL(blob);
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", "data.xlsx");
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     });
    // };



    // const testexcel = async () => {
    //     const result = [];

    //     dataArr?.forEach((e) => {
    //         const data = {}; // สร้างวัตถุใหม่ในแต่ละรอบ

    //         const addressNew = e?.addresses?.map((address) => {
    //             return `${address?.ADDRESS ?? " "} ${address?.TUMB ?? " "} ${address?.AUMP ?? " "} ${address?.PROVDES ?? " "} ${address?.ZIP ?? " "}`;
    //         }, []).join(" ");

    //         let filteredArrays;
    //         if (e?.NETPAY !== 0 && e?.NETPAY >= e?.PAYLIMITAMT) {
    //             filteredArrays = "จ่ายแล้ว"
    //         } else if (e?.NETPAY !== 0 && e?.NETPAY !== null && e?.NETPAY < e?.PAYLIMITAMT) {
    //             filteredArrays = "จ่ายยังไม่ถึงขั้นต่ำ"
    //         } else if (e?.NETPAY === 0 || e?.NETPAY === null) {
    //             filteredArrays = "ยังไม่จ่าย"
    //         }
    //         data.ARCONTNO = e.ARCONTNO;
    //         data.FullName = `${e?.SNAM || ""} ${e?.NAME1 || ""} ${e?.NAME2 || ""}`;
    //         data.TYPE = e?.TYPE + ' ' + e?.MODEL;
    //         data.addresses = addressNew;
    //         data.TELP = e?.TELP;
    //         // กำหนดค่าให้ STRNO หรือ REGNO ตามเงื่อนไข
    //         if (e.DATA_TYPE === "lsfhp") {
    //             data.STRNO = e.STRNO;
    //         } else {
    //             data.REGNO = e.REGNO;
    //         }
    //         data.GRDCOD = e?.GRDCOD;
    //         data.Status = filteredArrays ? filteredArrays : null

    //         // เพิ่มข้อมูลที่สร้างลงในอาร์เรย์
    //         result.push(data);
    //     });

    //     // console.log("result", result); // แสดงผลข้อมูลทั้งหมด
    //     return result; // ส่งคืนอาร์เรย์ข้อมูลทั้งหมด
    // };


    // report_all

    const loadData = async () => {
        setLoading(true)
        setDataLoaded(false);
        const param = {
            BILLCOLL: "MIT00048",
            date: dateselect
        }
        try {
            const response = await axios.post(get_report, param);
            // console.log("Response:", response.data);
            if (response.status === 200) {
                const allRecords = response?.data?.map(item => item?.data?.records || []).flat();
                allRecords.sort((a, b) => {
                    return (a.CONTNO || "").localeCompare(b.CONTNO || "");
                });
                const filteredRecords = allRecords.filter(item => {
                    const bilcoll = item?.BILLCOLL || "";
                    // กรองข้อมูลที่ BILLCOLL ตรงกับค่าที่กำหนดในอาร์เรย์
                    const allowedBILLCOLL = ["MIT00009", "MIT00035", "MIT00027", "MIT00005", "MIT00033", "MIT00078"];

                    return allowedBILLCOLL.includes(bilcoll); // ตรวจสอบว่า BILLCOLL อยู่ในอาร์เรย์
                });
                let countData = [
                    { key: 0, value: filteredRecords.length },
                    { key: 1, value: filteredRecords.filter(record => record.DATA_TYPE === "lsfhp").length },
                    { key: 2, value: filteredRecords.filter(record => record.DATA_TYPE === "vsfhp").length },
                    { key: 3, value: filteredRecords.filter(record => record.DATA_TYPE === "rpsl").length },
                    { key: 4, value: filteredRecords.filter(record => record.DATA_TYPE === "sfhp").length },
                ];
                let countDataUser = [
                    { key: 0, value: filteredRecords.length },
                    { key: 1, value: filteredRecords.filter(record => record.BILLCOLL === "MIT00009").length },
                    { key: 2, value: filteredRecords.filter(record => record.BILLCOLL === "MIT00035").length },
                    { key: 3, value: filteredRecords.filter(record => record.BILLCOLL === "MIT00027").length },
                    { key: 4, value: filteredRecords.filter(record => record.BILLCOLL === "MIT00005").length },
                    { key: 5, value: filteredRecords.filter(record => record.BILLCOLL === "MIT00033").length },
                    { key: 6, value: filteredRecords.filter(record => record.BILLCOLL === "MIT00078").length },
                ];
                setArrayTable(filteredRecords);
                setDataArr(filteredRecords);
                setDateFilter(filteredRecords);
                setCounts(countData);
                setCountsUser(countDataUser);
                setSelectedValueType(0);
                setSelectedValueUser(0);
                setUser("")
            } else {
                setArrayTable([]);
                setDataArr([]);
                showConfirmSave(response.data, param)
            }
        } catch (err) {
            console.error("API Error:", err);
            // showConfirmSave(param)
        }
        setLoading(false)
    }

    const showConfirmSave = (res, param) => {
        confirm({
            title: "ไม่พบข้อมูล",
            content: dayjs(param.date).format('DD-MM-YYYY') + ' ' + JSON.stringify(res.message),  // Convert object to a string
            okText: "OK",
            cancelText: "Cancel",
            onOk() {
                console.log("OK");
                setSelectedValueType(0);
                setSelectedValueUser(0);
                setArrayTable([]);
                setDataArr([]);
                setCounts([
                    { key: 0, value: 0 },
                    { key: 1, value: 0 },
                    { key: 2, value: 0 },
                    { key: 3, value: 0 },
                    { key: 4, value: 0 },
                ]);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };
    const showModalNoti = () => {
        confirm({
            title: "ไม่พบข้อมูลในตาราง",
            content: "โปรดตรวจสอบการดึงข้อมูล",  // Convert object to a string
            okText: "OK",
            cancelText: "Cancel",
            onOk() {
                console.log("OK");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const countoptionsUser = () => {
        const options = [
            { value: 0, label: `ทั้งหมด (${countsuser[0]?.value})` },
            { value: 1, label: `MIT00009 น้าเชษ (${countsuser[1]?.value})` },
            { value: 2, label: `MIT00035 วัฒนา (${countsuser[2]?.value})` },
            { value: 3, label: `MIT00027 บูม (${countsuser[3]?.value})` },
            { value: 4, label: `MIT00005 น้าหมู (${countsuser[4]?.value})` },
            { value: 5, label: `MIT00033 แป๊ะ (${countsuser[5]?.value})` },
            { value: 6, label: `MIT00078 บอม (${countsuser[6]?.value})` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }

    const countoptionsType = () => {
        const options = [
            { value: 0, label: `ทั้งหมด (${counts[0]?.value})` },
            { value: 1, label: `บัญชี 1 (${counts[1]?.value})` },
            { value: 2, label: `บัญชี 2 (${counts[2]?.value})` },
            { value: 3, label: `บัญชี 3 (${counts[3]?.value})` },
            { value: 4, label: `บัญชี 8 (${counts[4]?.value})` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }

    const onSelectUser = (value) => {
        console.log("value", value);

        setSelectedValueUser(value);
        let user
        let dataArrUser
        if (value === 1) {
            user = "MIT00009"
            setUser(user)
            dataArrUser = dataFilter?.filter(e => e.BILLCOLL === "MIT00009")
        } else if (value === 2) {
            user = "MIT00035"
            setUser(user)
            dataArrUser = dataFilter?.filter(e => e.BILLCOLL === "MIT00035")
        } else if (value === 3) {
            user = "MIT00027"
            setUser(user)
            dataArrUser = dataFilter?.filter(e => e.BILLCOLL === "MIT00027")
        } else if (value === 4) {
            user = "MIT00005"
            setUser(user)
            dataArrUser = dataFilter?.filter(e => e.BILLCOLL === "MIT00005")
        } else if (value === 5) {
            user = "MIT00033"
            setUser(user)
            dataArrUser = dataFilter?.filter(e => e.BILLCOLL === "MIT00033")
        } else if (value === 6) {
            user = "MIT00078"
            setUser(user)
            dataArrUser = dataFilter?.filter(e => e.BILLCOLL === "MIT00078")
        } else {
            user = "All"
            setUser(user)
            dataArrUser = dataFilter
        }
        // (value === 1) ? dataFilter?.filter(e => e.BILLCOLL === "MIT00009") :
        //     (value === 2) ? dataFilter?.filter(e => e.BILLCOLL === "MIT00035") :
        //         (value === 3) ? dataFilter?.filter(e => e.BILLCOLL === "MIT00027") :
        //             (value === 4) ? dataFilter?.filter(e => e.BILLCOLL === "MIT00005") :
        //                 (value === 5) ? dataFilter?.filter(e => e.BILLCOLL === "MIT00033") :
        //                     (value === 6) ? dataFilter?.filter(e => e.BILLCOLL === "MIT00078") :
        //                         dataArr;
        console.log("dataArrUser++++", dataArrUser);

        dataArrUser.sort((a, b) => {
            const contnoA = a.CONTNO || "";
            const contnoB = b.CONTNO || "";
            return contnoA.localeCompare(contnoB);
        });
        let countDataUser = [];
        countDataUser = [
            { key: 0, value: dataArrUser.length },
            { key: 1, value: dataArrUser.filter(record => record.DATA_TYPE === "lsfhp").length },
            { key: 2, value: dataArrUser.filter(record => record.DATA_TYPE === "vsfhp").length },
            { key: 3, value: dataArrUser.filter(record => record.DATA_TYPE === "rpsl").length },
            { key: 4, value: dataArrUser.filter(record => record.DATA_TYPE === "sfhp").length },
        ];
        setCounts(countDataUser)
        setArrayTable(dataArrUser)
    };


    const onSelectType = (value) => {
        // console.log("TYPE", value)
        setSelectedValueType(value);
        setSelectedValueUser(0);
        const dataArrFilter = (value === 1) ? dataArr?.filter(e => e.DATA_TYPE === "lsfhp") :
            (value === 2) ? dataArr?.filter(e => e.DATA_TYPE === "vsfhp") :
                (value === 3) ? dataArr?.filter(e => e.DATA_TYPE === "rpsl") :
                    (value === 4) ? dataArr?.filter(e => e.DATA_TYPE === "sfhp") :
                        dataArr;
        // console.log("dataArrFilter++++", dataArrFilter);
        let countDataUser = [];
        countDataUser = [
            { key: 0, value: dataArrFilter.length },
            { key: 1, value: dataArrFilter.filter(record => record.BILLCOLL === "MIT00009").length },
            { key: 2, value: dataArrFilter.filter(record => record.BILLCOLL === "MIT00035").length },
            { key: 3, value: dataArrFilter.filter(record => record.BILLCOLL === "MIT00027").length },
            { key: 4, value: dataArrFilter.filter(record => record.BILLCOLL === "MIT00005").length },
            { key: 5, value: dataArrFilter.filter(record => record.BILLCOLL === "MIT00033").length },
            { key: 6, value: dataArrFilter.filter(record => record.BILLCOLL === "MIT00078").length },
        ];

        dataArrFilter.sort((a, b) => {
            const contnoA = a.CONTNO || "";
            const contnoB = b.CONTNO || "";
            return contnoA.localeCompare(contnoB);
        });
        setCountsUser(countDataUser);
        setDateFilter(dataArrFilter)
        setArrayTable(dataArrFilter)
    };


    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return 0;
        }
    };


    const columns = [
        {
            title: "ชื่อลูกค้า",
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: 'center',
            render: (text, record1, index) => {
                // console.log("records 1", record1)
                const dataSources = [record1]
                const filteredData = dataSources.filter(record => record.products !== 'land');
                if (record1?.products === 'land') {
                    return null;
                }
                const dataNew = () => {
                    return (
                        <>
                            <b>{record1?.CONTNO ? record1?.CONTNO : null}</b>
                            <div>{record1.SNAM} {record1.NAME1} {record1.NAME2}</div>
                        </>
                    )
                }

                return (
                    <div>
                        {dataNew()}
                    </div>
                );

            },
        },
        {
            title: "สินค้า",
            dataIndex: "TYPE",
            key: "TYPE",
            align: 'center',
            render: (text, record, index) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                    >
                        {record?.DATA_TYPE === "lsfhp" ?
                            <>
                                {record.TYPE ? record.TYPE + ' ' : null}
                                {record.MODEL ? record.MODEL + ' ' : null}
                                {record.BAAB ? record.BAAB + ' ' : null}
                                {record.STRNO ? 'เลขโฉนด' + ' ' + record.STRNO : null}
                            </>
                            :
                            <>
                                {
                                    record.BAAB || record.TYPE || record.MODEL || record.MANUYR || record.REGNO ?
                                        <div>
                                            {record.TYPE ? record.TYPE + ' ' : null}
                                            {record.MODEL ? record.MODEL + ' ' : null}
                                            {record.BAAB ? record.BAAB + ' ' : null}
                                            {record.REGNO ? ' เลขทะเบียน' + ' ' + record.REGNO : null}
                                        </div>
                                        : null
                                }
                            </>
                        }

                    </div>
                )
            },
        },
        {
            title: "ยอดผ่อน",
            dataIndex: "TOTPRC",
            key: "TOTPRC",
            align: 'center',
            render: (text, record, index) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    {currencyFormatOne(record?.TOTPRC)}
                </div>
            ),
        },
        {
            title: "งวดละ",
            dataIndex: "TOTUPAY",
            key: "TOTUPAY",
            align: 'center',
            render: (text, record, index) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    {currencyFormatOne(record?.TOTUPAY)}
                </div>
            ),
        },
        {
            title: "ค้างงวด",
            dataIndex: "KANG",
            key: "KANG",
            align: 'center',
            render: (text, record, index) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    {'จากงวด' + ' ' + record?.KANG}<br />
                    {'ประมาณ ' + ' ' + record?.EXPREAL}
                </div>
            ),
        },
        {
            title: "เงินค้างค่างวด / ค้างค่าปรับ",
            dataIndex: "GRDCOD",
            key: "GRDCOD",
            align: 'center',
            render: (text, record, index) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    {currencyFormatOne(record?.TOTLKANG)}<br />
                    {currencyFormatOne(record?.KINTAMT)}
                </div>
            ),
        },
        {
            title: "ชำระล่าสุด",
            dataIndex: "LPAYD",
            key: "LPAYD",
            align: 'center',
            render: (text, record, index) => {
                return (
                    <>
                        วันที่  {record?.LPAYD ? dayjs(record?.LPAYD).format("DD-MM-YYYY") : '-'}<br />
                        จำนวนเงิน {record?.LPAYA ? currencyFormatOne(record?.LPAYA) : '-'}
                    </>
                )
            },
        },
        {
            title: "ขาดการติดต่อ(วัน)",
            dataIndex: "LOSTDAY",
            key: "LOSTDAY",
            align: 'center',
            render: (text, record, index) => (
                <>
                    {record?.LOSTDAY}
                </>
            ),
        },
        {
            title: "ลูกหนี้คงเหลือ / ชำระค่าปรับ",
            dataIndex: "KARBAL",
            key: "KARBAL",
            align: 'center',
            render: (text, record, index) => {
                return (
                    <>
                        {currencyFormatOne(record?.KARBAL)}
                    </>
                )
            },
        },
        {
            title: "ชำระขั้นต่ำ / ทีมตาม",
            dataIndex: "BILLCOLL",
            key: "BILLCOLL",
            align: 'center',
            render: (text, record, index) => {
                return (
                    <>
                        {currencyFormatOne(record?.PAYLIMITAMT)}
                        <br />
                        {record?.BILLCOLL}
                    </>
                )
            },
        },
        {
            title: "เกรด",
            dataIndex: "GRDCOD",
            key: "GRDCOD",
            align: 'center',
            render: (text, record, index) => (
                <div data-label="เกรด : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    {record.GRDCOD || "ไม่มีเลขสัญญา"}
                </div>
            ),
        },

    ];

    return (
        <div>
            <Card style={{ height: '100%', fontSize: '20px', }}      >
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h2>รายงาน</h2>
                </Col>
                <Spin spinning={loading} size="large" tip="Loading..." >
                    <Row justify={'center'}>
                        <Tag style={{ margin: 8, padding: 10, background: 'gray', color: 'white' }}> {`ทั้งหมด (${counts[0]?.value})`}</Tag>
                        <Tag style={{ margin: 8, padding: 10, background: 'green', color: 'white' }}> {`บัญชี 1 (${counts[1]?.value})`}</Tag>
                        <Tag style={{ margin: 8, padding: 10, background: 'blue', color: 'white' }}> {`บัญชี 2 (${counts[2]?.value})`}</Tag>
                        <Tag style={{ margin: 8, padding: 10, background: 'orange' }}> {`บัญชี 3 (${counts[3]?.value})`}</Tag>
                        <Tag style={{ margin: 8, padding: 10, background: 'yellow' }}> {`บัญชี 8 (${counts[4]?.value})`}</Tag>
                    </Row>
                    <Form>
                        <Row justify={'center'} style={{ margin: 10 }}>
                            <Form.Item name="date">
                                <div style={{ textAlign: 'center' }}>
                                    <span>วันที่ : </span>
                                    <DatePicker
                                        name="date"
                                        defaultValue={dayCurrent}
                                        format={'YYYY-MM-DD'}
                                        style={{ height: '40px', width: 250 }}
                                        inputReadOnly
                                        picker="month"  // ใช้ picker แบบเดือน
                                        onChange={handleChangeDate}
                                    />
                                </div>
                            </Form.Item>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button

                                    style={{ marginRight: "10px", height: '40px', color: 'white', background: '#302e94f7' }}
                                    onClick={loadData}
                                >
                                    <FaDatabase />
                                    <b>ดึงข้อมูล</b>
                                </Button>
                            </div>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <span>เลือกผู้ใช้ : </span>
                                <Select
                                    disabled={dataLoaded}
                                    style={{
                                        width: 280,
                                        height: '40px'
                                    }}
                                    value={selectedValueUser}
                                    onChange={onSelectUser}
                                >
                                    {countoptionsUser()}
                                </Select>
                            </div>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <span>เลือกบัญชี : </span>
                                <Select
                                    disabled={dataLoaded}
                                    style={{
                                        width: 280,
                                        height: '40px'
                                    }}
                                    value={selectedValueType}
                                    onChange={onSelectType}
                                >
                                    {countoptionsType()}
                                </Select>
                            </div>
                            {/* <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button
                                    disabled={dataLoaded}
                                    style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                    onClick={exportToExcel}
                                >
                                    <FaDatabase />
                                    <b>Export Excel</b>
                                </Button>
                            </div> */}
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button
                                    disabled={dataLoaded}
                                    style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                    onClick={exportToPDF}
                                >
                                    <FaDatabase />
                                    <b>Export PDF</b>
                                </Button>
                            </div>

                        </Row>
                    </Form>
                    <div className="responsive-table">
                        <Row>
                            <Table
                                style={{ marginTop: "5px" }}
                                scroll={{
                                    y: "600px",
                                }}
                                dataSource={arrayTable}
                                columns={columns}
                            ></Table>
                        </Row>

                    </div>
                </Spin>
            </Card>
        </div>
    );
}

export default MainReport;