import { Col, Row, Spin, Card, Table, Tag, Space, Input, Button, Select, Form, Checkbox, message, Divider } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import React, { useEffect } from 'react'
import { useState } from 'react';
import { SearchOutlined, } from "@ant-design/icons";
import { FaAngleDoubleUp, FaArrowCircleUp, FaDatabase, FaSortDown, FaSortUp, } from "react-icons/fa";
import { results } from '../mid/all-api';
import { hover } from '@testing-library/user-event/dist/hover';
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { font } from "../../fonts/THSarabunNew-normal";
import ExcelJS from 'exceljs';
import { useLocation } from 'react-router-dom';

const MainNew = () => {
    const [dataArray, setDataArray] = useState()
    const [arrayTable, setArrayTable] = useState()
    const [loading, setLoading] = useState(false);
    const [queryContno, setQueryContno] = useState("");
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedValueType, setSelectedValueType] = useState(1);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandedRowsPay, setExpandedRowsPay] = useState([]);
    const user = localStorage.getItem('username')
    const nicknameSalcod = localStorage.getItem('nicknameSalcod')
    const [checkLoadData, setCheckLoadData] = useState(0);
    const [dataforsearch, setDataForSearch] = useState({
        takebrotheraun: false,
        takethecasetoclose: false,
        excludingseizedvehicles: false,
    });
    const [listbeforeSelect, setListBeforeSelect] = useState();
    const [countclose, setCountClose] = useState();
    const [countMk, setCountMk] = useState();
    const [countTellAun, setCountTellAun] = useState();
    const [countclosedata, setCountClosedata] = useState();
    const [countMkdata, setCountMkdata] = useState();
    const [countTellAundata, setCountTellAundata] = useState();
    const [changecheck, setChangeCheck] = useState()
    const [CountsBukup, setCountsBukup] = useState()
    const thaiFont = font;
    const [scrollY, setScrollY] = useState(window.innerHeight);
    const [scrollX, setScrollX] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY; // ระยะที่เลื่อนลงมา
            const bottomPosition = document.documentElement.scrollHeight - window.innerHeight; // ระยะที่ถึงขอบล่างของเว็บ
            setShowButton(scrollPosition >= bottomPosition - 50); // แสดงปุ่มเมื่อใกล้ขอบล่าง

        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768);
            setScrollY(window.innerHeight);
            setScrollX(window.innerWidth);
        }
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    // ซ่อนปุ่มเมื่อ pathname เปลี่ยน
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);


    const calculateTableHeight = () => {
        const headerHeight = isMobile && 70; // ความสูงของ Header
        const footerHeight = isMobile ? 70 : 0; // ความสูงของ Footer (มือถือมี Pagination)
        const marginHeight = 150; // Margin ระหว่างส่วนต่างๆ
        const otherComponentsHeight = 150; // ความสูงของส่วนอื่นๆ (ปุ่ม, Select, Input ฯลฯ)
        let cal;
        if (scrollX <= scrollY && scrollX >= 375) {
            cal = scrollY - (headerHeight + marginHeight + 150);
        } else if (scrollX <= scrollY) {
            cal = scrollY - (headerHeight + marginHeight + 70);
        } else {
            cal = scrollY - (headerHeight + footerHeight + marginHeight + otherComponentsHeight + 70);
        }
        console.log("cal", cal)
        return cal;
    };

    const [counts, setCounts] = useState([
        { key: 0, value: 0 },
        { key: 1, value: 0 },
        { key: 2, value: 0 },
        { key: 3, value: 0 },
    ]);

    const exportToPDF = async () => {
        console.log("exportToPDF")
        // const { jsPDF } = window.jspdf;
        const doc = new jsPDF({
            orientation: "landscape", // ตั้งค่ากระดาษแนวนอน
            unit: "mm", // หน่วย (เช่น mm, pt)
            format: "a4" // ขนาดกระดาษ (A4, Letter, ฯลฯ)
        });
        const result = await testreport(); // ดึงข้อมูลที่เตรียมไว้

        // ฝังฟอนต์ไทยใน PDF
        doc.addFileToVFS("THSarabunNew.ttf", thaiFont);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");

        // ตั้งค่าให้ใช้ฟอนต์ไทย
        doc.setFont("THSarabunNew");
        doc.setFontSize(16);

        doc.text(`รับเข้าค่างวด - ${user}`, doc.internal.pageSize.width / 2, 10, {
            align: "center" // จัดข้อความให้อยู่ตรงกลาง
        });

        // รวมข้อมูลโดยการอัปเดต Status ใน dataArr จาก result
        const mergedData = dataArray.map(item => {
            // หา Status ที่ตรงกับ ARCONTNO ใน result
            const matchedResult = result.filter((res) =>
                res.ARCONTNO === item.CONTNO
            );
            // ถ้าเจอ matchedResult ให้อัปเดต Status
            return {
                ...item,
                Status: matchedResult ? matchedResult[0]?.Status : item.Status // ใช้ Status จาก result ถ้ามี
            };
        });
        // console.log("mergedData", mergedData)
        // เขียนข้อความภาษาไทย
        const headers_car = ["เลขสัญญา", "ชื่อลูกค้า", "รถ", "ที่อยู่", "เบอร์โทร", "ทะเบียน", "เกรด", "สถานะ"];
        const headers_land = ["เลขสัญญา", "ชื่อลูกค้า", "ที่ดิน", "ที่อยู่", "เบอร์โทร", "โฉนด", "เกรด", "สถานะ"];
        const headers = selectedValueType === 1 ? headers_land : headers_car;
        const data = mergedData.map(item => [
            item?.ARCONTNO,
            item?.SNAM + ' ' + item?.NAME1 + ' ' + item?.NAME2,
            item?.TYPE + ' ' + item?.MODEL,
            item?.addresses
                .map(
                    (addr, index) =>
                        `${'ที่อยู่ ' + (index + 1)} :  ${addr?.ADDRESS}, ต.${addr?.TUMB}, อ.${addr?.AUMP}, จ.${addr?.PROVDES}, ${addr?.ZIP}`
                )
                .join("\n"),
            item?.TELP,
            item?.DATA_TYPE === "lsfhp" ? item?.STRNO : item?.REGNO,
            item?.GRDCOD,
            item?.Status
        ]);
        // { DATA_TYPE: 'lsfhp' }, 1
        // { DATA_TYPE: 'vsfhp' }, 2
        // { DATA_TYPE: 'rpsl' }, 3
        // { DATA_TYPE: 'sfhp' }, 8
        // สร้างตาราง
        doc.autoTable({
            head: [headers],
            body: data,
            startY: 15, // เริ่มวาดตารางจากตำแหน่ง Y = 40
            styles: {
                font: "THSarabunNew", // ใช้ฟอนต์ไทยที่เราฝังไว้
                fontSize: 14 // ขนาดฟอนต์ในตาราง
            },
            headStyles: {
                fillColor: [100, 149, 237], // สีพื้นหลังหัวตาราง (Cornflower Blue)
                textColor: [255, 255, 255]  // สีข้อความในหัวตาราง (สีขาว)
            },
            didDrawPage: (data) => {
                // เพิ่มหมายเลขหน้า
                const pageNumber = doc.internal.getNumberOfPages();
                doc.setFontSize(12);
                doc.text(`หน้าที่ ${pageNumber}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
            }
        });
        doc.save("report-th.pdf");
    }

    const exportToExcel = async () => {
        const user = localStorage.getItem("username") || "ไม่ระบุชื่อผู้ใช้งาน";
        const result = await testexcel(); // ดึงข้อมูลที่เตรียมไว้
        console.log("result+__++_+_+_", result)
        const workbook = new ExcelJS.Workbook();
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
            const worksheet = workbook.addWorksheet(sheetName);
            worksheet.mergeCells("A1:F1"); // รวมเซลล์
            const titleCell = worksheet.getCell("A1");
            titleCell.value = `รับเข้าค่างวด - ${user}`;
            titleCell.alignment = { horizontal: "center", vertical: "middle" };
            titleCell.font = { bold: true, size: 16 };

            worksheet.addRow(headers_thai).font = { bold: true };
            worksheet.columns = [
                { key: "เลขสัญญา", width: 15 },
                { key: "ชื่อลูกค้า", width: 25 },
                { key: "สินค้า", width: 30 },
                { key: "ที่อยู่", width: 35 },
                { key: "ทะเบียน", width: 10 },
                { key: "เบอร์โทร", width: 10 },
                { key: "เกรด", width: 8, horizontal: "center", vertical: "middle" },
                { key: "สถานะ", width: 20, horizontal: "center", vertical: "middle" },
            ];
            dataSource.forEach((row) => {
                const rowData = headers_colums_name.map((column) => row[column]);
                worksheet.addRow(rowData);
            });
        };

        const headers_thai = ["เลขสัญญา", "ชื่อลูกค้า", "สินค้า", "ที่อยู่", "เบอร์โทร", selectedValueType === 1 ? "โฉนด" : "ทะเบียน", "เกรด", "สถานะ"];
        const headers_colums_name = ["ARCONTNO", "FullName", "TYPE", "addresses", "TELP", selectedValueType === 1 ? "STRNO" : "REGNO", "GRDCOD", "Status"];

        let results = result.filter((item) => (item.Status === "เข้าค่างวด"))
        let results1 = result.filter((item) => (item.Status === "รับฝากแล้ว"))
        let results2 = result.filter((item) => (item.Status === "เข้าค่างวดแต่ไม่ถึงขั้นต่ำ"))

        if (results?.length > 0) {

            addSheet("เข้าค่างวด", results, headers_colums_name, headers_thai);
        }
        if (results1?.length > 0) {

            addSheet("รับฝากแล้ว", results1, headers_colums_name, headers_thai);
        }
        if (results2?.length > 0) {
            addSheet("เข้าค่างวดแต่ไม่ถึงขั้นต่ำ", results2, headers_colums_name, headers_thai);
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "data.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const testreport = async () => {
        const result = [];

        dataArray?.forEach((e) => {
            const data = {}; // สร้างวัตถุใหม่ในแต่ละรอบ

            const addressNew = e?.addresses?.map((address) => {
                return `${address?.ADDRESS ?? " "} ${address?.TUMB ?? " "} ${address?.AUMP ?? " "} ${address?.PROVDES ?? " "} ${address?.ZIP ?? " "}`;
            }, []).join(" ");

            let filteredArrays;
            if ((e.TMBILL !== "" && e.PAYDT && e.NETPAY >= e.PAYLIMITAMT) || // เงื่อนไขแรก
                (e.TMBILL !== "" && e.NETPAY >= e.PAYLIMITAMT && e?.HDSUM !== 0 && e?.HDTEMPDATE !== null)) { // เงื่อนไขสอง
                filteredArrays = "เข้าค่างวด"
            } else if (e?.HDSUM !== 0 && e?.HDTEMPDATE !== "") {
                filteredArrays = "รับฝากแล้ว"
            } else if (e.TMBILL !== "" && e.PAYDT !== null && e.NETPAY < e?.PAYLIMITAMT) {
                filteredArrays = "เข้าค่างวดแต่ไม่ถึงขั้นต่ำ"
            }
            data.ARCONTNO = e.ARCONTNO;
            data.FullName = `${e?.SNAM || ""} ${e?.NAME1 || ""} ${e?.NAME2 || ""}`;
            data.TYPE = e?.TYPE + ' ' + e?.MODEL;
            data.addresses = addressNew;
            data.TELP = e?.TELP;
            // กำหนดค่าให้ STRNO หรือ REGNO ตามเงื่อนไข
            if (e.DATA_TYPE === "lsfhp") {
                data.STRNO = e.STRNO;
            } else {
                data.REGNO = e.REGNO;
            }
            data.GRDCOD = e?.GRDCOD;
            data.Status = filteredArrays ? filteredArrays : null

            // เพิ่มข้อมูลที่สร้างลงในอาร์เรย์
            result.push(data);
        });

        // console.log("result", result); // แสดงผลข้อมูลทั้งหมด
        return result; // ส่งคืนอาร์เรย์ข้อมูลทั้งหมด
    };
    const testexcel = async () => {
        const result = [];

        arrayTable?.forEach((e) => {
            const data = {}; // สร้างวัตถุใหม่ในแต่ละรอบ

            const addressNew = e?.addresses?.map((address) => {
                return `${address?.ADDRESS ?? " "} ${address?.TUMB ?? " "} ${address?.AUMP ?? " "} ${address?.PROVDES ?? " "} ${address?.ZIP ?? " "}`;
            }, []).join(" ");

            let filteredArrays;
            if ((e.TMBILL !== "" && e.PAYDT && e.NETPAY >= e.PAYLIMITAMT) || // เงื่อนไขแรก
                (e.TMBILL !== "" && e.NETPAY >= e.PAYLIMITAMT && e?.HDSUM !== 0 && e?.HDTEMPDATE !== null)) { // เงื่อนไขสอง
                filteredArrays = "เข้าค่างวด"
            } else if (e?.HDSUM !== 0 && e?.HDTEMPDATE !== "") {
                filteredArrays = "รับฝากแล้ว"
            } else if (e.TMBILL !== "" && e.PAYDT !== null && e.NETPAY < e?.PAYLIMITAMT) {
                filteredArrays = "เข้าค่างวดแต่ไม่ถึงขั้นต่ำ"
            }
            data.ARCONTNO = e.ARCONTNO;
            data.FullName = `${e?.SNAM || ""} ${e?.NAME1 || ""} ${e?.NAME2 || ""}`;
            data.TYPE = e?.TYPE + ' ' + e?.MODEL;
            data.addresses = addressNew;
            data.TELP = e?.TELP;
            // กำหนดค่าให้ STRNO หรือ REGNO ตามเงื่อนไข
            if (e.DATA_TYPE === "lsfhp") {
                data.STRNO = e.STRNO;
            } else {
                data.REGNO = e.REGNO;
            }
            data.GRDCOD = e?.GRDCOD;
            data.Status = filteredArrays ? filteredArrays : null

            // เพิ่มข้อมูลที่สร้างลงในอาร์เรย์
            result.push(data);
        });

        // console.log("result", result); // แสดงผลข้อมูลทั้งหมด
        return result; // ส่งคืนอาร์เรย์ข้อมูลทั้งหมด
    };

    // ดึงสัญญา
    const onQueryContno = async () => {
        setDataForSearch({
            takebrotheraun: false,
            takethecasetoclose: false,
            excludingseizedvehicles: false,
        });
        setQueryContno("")
        var data_type
        if (selectedValueType === 1) {
            data_type = 'lsfhp'
        } else if (selectedValueType === 2) {
            data_type = 'vsfhp'
        } else if (selectedValueType === 3) {
            data_type = 'rpsl'
        } else if (selectedValueType === 4) {
            data_type = 'sfhp'
        }
        // { DATA_TYPE: 'rpsl' }, 3
        // { DATA_TYPE: 'vsfhp' }, 2
        // { DATA_TYPE: 'lsfhp' }, 1
        // { DATA_TYPE: 'sfhp' }, 8

        const papams = {
            BILLCOLL: user,
            nicknameSalcod: nicknameSalcod,
            DATA_TYPE: data_type

        }
        // console.log("dataNewFor Get DATA papams", papams)
        setLoading(true);
        await axios.post(results, papams)
            .then(res => {
                // console.log("resQueryNew", res.data)
                let i = 1;
                if (res.data) {
                    const newData = res.data.map((item) => ({
                        ...item,
                        key: i++,
                    }));

                    let groupedData = new Map(); // ใช้ Map สำหรับเก็บ grouped data
                    let expandedData = []; // ข้อมูลที่รวมแล้ว

                    newData.forEach((ii, index) => {
                        if (ii?.dataList && ii.dataList.length >= 1) {
                            ii.dataList.forEach((listData, listIndex) => {
                                const key = `${ii.CONTNO}-${listData.PAYDT || 'null'}`; // ใช้ CONTNO และ PAYDT เป็น key
                                if (groupedData.has(key)) {
                                    // หาก key นี้มีอยู่แล้ว ให้รวมรายการใน dataList
                                    if (listData.NETPAY >= ii.PAYLIMITAMT) {
                                        const existing = groupedData.get(key);
                                        groupedData.set(key, {
                                            ...existing,
                                            NETPAY: (existing.NETPAY || 0) + (listData.NETPAY || 0), // รวมค่า NETPAY
                                            dataList: [...existing.dataList, listData], // รวมรายการใน dataList
                                        });
                                    }
                                } else {
                                    // หาก key นี้ยังไม่มีใน groupedData ให้สร้างใหม่
                                    groupedData.set(key, {
                                        ...ii,
                                        ...listData,
                                        HDSUM: listData.resTOTAMT,
                                        PAYDT: listData.PAYDT, // ใช้ PAYDT จาก listData
                                        NETPAY: listData.NETPAY || 0, // กำหนดค่าเริ่มต้น NETPAY
                                        dataList: [listData], // เริ่มต้นด้วยรายการเดียว
                                        key: `${ii.CONTNO || 'unknown'}-${index}-${listIndex}`, // สร้าง key เฉพาะ
                                    });
                                }
                            });
                        } else {
                            // กรณีไม่มี dataList ให้เพิ่มข้อมูลใน expandedData โดยตรง
                            expandedData.push({
                                ...ii,
                                key: `${ii.CONTNO || 'unknown'}-${index}`,
                            });
                        }
                    });

                    // เพิ่มข้อมูลจาก groupedData ลงใน expandedData
                    expandedData = [...expandedData, ...Array.from(groupedData.values())];

                    // เรียงตามเลขสัญญา น้อย => มาก
                    expandedData.sort((a, b) => {
                        const contnoA = a.CONTNO || "";
                        const contnoB = b.CONTNO || "";

                        if (contnoA < contnoB) return -1;
                        if (contnoA > contnoB) return 1;
                        return 0; // กรณีที่เท่ากัน
                    });

                    let countData = []; // ใช้เก็บ countData ใหม่

                    // ขั้นแรก: กรองเฉพาะรายการที่มี NETPAY && HDSUM
                    const validData = expandedData.filter(record => record?.NETPAY || record?.HDSUM);
                    countData = [
                        {
                            key: 0,
                            value: validData.length // จำนวนทั้งหมดที่มี NETPAY
                        },
                        {
                            key: 1,
                            value: validData.filter(record => {
                                // เงื่อนไขแรกหรือเงื่อนไขสอง
                                return (
                                    (record.TMBILL !== "" && record.PAYDT && record.NETPAY >= record.PAYLIMITAMT) || // เงื่อนไขแรก
                                    (record.TMBILL !== "" && record.NETPAY >= record.PAYLIMITAMT && record?.HDSUM !== 0 && record?.HDTEMPDATE !== null) // เงื่อนไขสอง
                                );
                            }).length
                        },
                        {
                            key: 2,
                            value: validData.filter(record => record?.HDSUM !== 0 && record?.HDTEMPDATE !== "").length // เงื่อนไข รับฝาก
                        },
                        {
                            key: 3,
                            value: validData.filter(record =>
                                record.TMBILL !== "" &&
                                record.PAYDT !== null &&
                                record.NETPAY < record?.PAYLIMITAMT // เงื่อนไขเข้าค่างวดแต่ไม่ถึงขั้นต่ำ
                            ).length
                        },

                    ];

                    var aun = []
                    var close = []
                    var mk = []
                    validData.map((item) => {
                        if (item?.flagTellAun) {
                            aun.push(item)
                        } if (item?.flagClose) {
                            close.push(item)
                        } if (item?.flagOffer) {
                            mk.push(item)
                        }
                    });
                    setListBeforeSelect(validData)
                    setCountTellAun(aun.length)
                    setCountClose(close.length)
                    setCountMk(mk.length)
                    setChangeCheck(validData)
                    setCountTellAundata(aun)
                    setCountClosedata(close)
                    setCountMkdata(mk)
                    setCounts(countData);
                    setCountsBukup(countData);
                    setArrayTable(validData);
                    setDataArray(validData);
                    setCheckLoadData(1)
                    setSelectedValue(0);
                    setLoading(false);
                } else {
                    setDataArray([]);
                    message.error("ไม่มีเลขที่สัญญาที่ค้นหา");
                }
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return 0;
        }
    };

    const handleChange = (key, value) => {
        setDataForSearch((prev) => ({
            ...prev,
            [key]: value, // อัปเดตค่าของ key ที่เปลี่ยนแปลง
        }));

        const { excludingseizedvehicles, takebrotheraun, takethecasetoclose } = { ...dataforsearch, [key]: value };
        if (!excludingseizedvehicles && !takebrotheraun && !takethecasetoclose) {
            setCounts(CountsBukup)
            setDataArray(changecheck)
        } else {
            setCounts(CountsBukup)
            // รวมข้อมูลที่มีการติ๊ก (true)
            let combinedData = [];

            if (takebrotheraun) {  // เคสเร่งรัดภาคสนาม
                combinedData = [...combinedData, ...countTellAundata];
            }

            if (takethecasetoclose) {  // เคสที่ปิดไปแล้ว
                combinedData = [...combinedData, ...countclosedata];
            }

            if (excludingseizedvehicles) {  // เคสที่การตลาดเอาไปเสนอ
                combinedData = [...combinedData, ...countMkdata];
            }
            //หาค่าซ้ำ
            const uniqueCombinedData = Array.from(new Set(combinedData));
            // ข้อมูลที่ซ้ำกัน
            const matchingData = changecheck.filter(
                item => !uniqueCombinedData?.some(uniqueItem => uniqueItem?.CONTNO === item?.CONTNO)
            );

            const mergeDuplicateKeys = (array) => {
                // ใช้ reduce เพื่อสร้างออบเจ็กต์ที่รวมค่าเฉพาะ key
                const mergedData = array.reduce((acc, item) => {
                    if (acc[item.key]) {
                        // ถ้า key ซ้ำ อัปเดต value
                        acc[item.key].value = item.value;
                    } else {
                        // ถ้า key ไม่ซ้ำ เพิ่มออบเจ็กต์เข้าไป
                        acc[item.key] = { ...item };
                    }
                    return acc;
                }, {});

                // แปลงกลับเป็น array
                return Object.values(mergedData);
            };
            const countData = [
                ...counts,
                { key: selectedValue, value: matchingData?.length },
            ]
            // เก็บค่าจาก mergeDuplicateKeys() เก็บไว้ในตัวแปร result
            const result = mergeDuplicateKeys(countData);

            setCounts(result)
            setDataArray(matchingData);
        }

    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setQueryContno(inputValue);
        if (inputValue === '') {
            setDataArray(changecheck)
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onQuery();  // เรียกฟังก์ชันค้นหาหลังจากกด Enter
        }
    };

    const onQuery = () => {
        if (queryContno === '') {
            setDataArray(changecheck)
        } else {
            let lowerQuery = queryContno.trim().toLowerCase();
            let result = changecheck.filter(
                (item) =>
                    (item.CONTNO && item.CONTNO.toLowerCase().includes(lowerQuery)) || // เลขสัญญา
                    (item.NAME1 && item.NAME1.toLowerCase().includes(lowerQuery)) || // ชื่อ
                    (item.NAME2 && item.NAME2.toLowerCase().includes(lowerQuery)) || // นามสกุล
                    (item.REGNO && item.REGNO.toLowerCase().includes(lowerQuery)) || // ทะเบีย หรือ เลขโฉนด
                    (item.STRNO && item.STRNO.toLowerCase().includes(lowerQuery)) || //เลขคัสซี
                    // (item.TYPE && item.TYPE.toLowerCase().includes(lowerQuery)) ||    //ยี่ห้อ
                    // (item.MODEL && item.MODEL.toLowerCase().includes(lowerQuery)) ||   //รุ่น
                    // (item.BAAB && item.BAAB.toLowerCase().includes(lowerQuery)) ||   //แบบ
                    (item.TELP && item.TELP.toLowerCase().includes(lowerQuery))   //เบอร์โทร
            );
            console.log("result", result)
            setDataArray(result);
        }
    };

    const countoptions = () => {
        const options = [
            { value: 0, label: `ทั้งหมด(${counts[0].value})` },
            { value: 1, label: `เข้าค่างวด(${counts[1].value})` },
            { value: 2, label: `รับฝากแล้ว(${counts[2].value})` },
            { value: 3, label: `เข้าค่างวดแต่ไม่ถึงขึ้นต่ำ(${counts[3].value})` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }
    const countoptionsType = () => {
        const options = [
            // { value: 0, label: `ทั้งหมด` },
            { value: 1, label: `บัญชี 1` },
            { value: 2, label: `บัญชี 2` },
            { value: 3, label: `บัญชี 3` },
            { value: 4, label: `บัญชี 8` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }
    const onSelectType = (value) => {
        console.log("valurrereee", value)
        setSelectedValueType(value);
        setSelectedValue("")
        setQueryContno("")
    };

    const onSelect = (value) => {
        setSelectedValue(value);
        setQueryContno("")
        setDataForSearch({
            takebrotheraun: false,
            takethecasetoclose: false,
            excludingseizedvehicles: false,
        });
        // PAYLIMITAMT การจ่ายขั้นต่ำ 
        // NETPAY จ่ายเข้ามา 
        let filteredArrays = [];
        var aun = []
        var close = []
        var mk = []

        if (value === 0) {
            filteredArrays = listbeforeSelect
            // console.log("filteredArrays000", filteredArrays)
            setDataArray(filteredArrays);
        } else if (value === 1) { // เข้าค่างวด
            // ถ้า NETPAY !== 0 && NETPAY >= PAYLIMITAMT  
            filteredArrays = listbeforeSelect?.filter(record => {
                let mapData = []
                if (
                    (record.TMBILL !== "" && record.PAYDT && record.NETPAY >= record?.PAYLIMITAMT) || // เงื่อนไขแรก
                    (
                        record.TMBILL !== "" && record.NETPAY >= record?.PAYLIMITAMT && // เงื่อนไขสอง
                        record?.HDSUM !== 0 && record?.HDTEMPDATE !== null
                    )
                ) { mapData.push(record); }
                // Debug: ดูว่า mapData มีข้อมูลหรือไม่
                return mapData.length > 0;
            })
            // console.log("filteredArrays111", filteredArrays)
            filteredArrays.sort((a, b) => {
                const contnoA = a.PAYDT || "";
                const contnoB = b.PAYDT || "";

                if (contnoA > contnoB) return -1;
                if (contnoA < contnoB) return 1;
                return 0; // กรณีที่เท่ากัน
            });
            setDataArray(filteredArrays);
        } else if (value === 2) { // รับฝาก
            // ถ้า NETPAY !== 0 && NETPAY < PAYLIMITAMT
            filteredArrays = listbeforeSelect?.filter(record => record?.HDSUM !== 0 && record?.HDTEMPDATE);
            // console.log("filteredArrays222", filteredArrays)
            filteredArrays.sort((a, b) => {
                const contnoA = a.HDTEMPDATE || "";
                const contnoB = b.HDTEMPDATE || "";

                if (contnoA > contnoB) return -1;
                if (contnoA < contnoB) return 1;
                return 0; // กรณีที่เท่ากัน
            });
            setDataArray(filteredArrays);
        } else if (value === 3) { // เข้าค่างวดแต่ไม่ถึงขั้นต่ำ
            filteredArrays = listbeforeSelect?.filter(record => record.TMBILL !== "" && record.PAYDT !== null && (record.NETPAY < record?.PAYLIMITAMT));
            // console.log("filteredArrays333", filteredArrays)
            setDataArray(filteredArrays);
        }

        filteredArrays.map((item) => {
            if (item?.flagTellAun) {
                aun.push(item)
            } if (item?.flagClose) {
                close.push(item)
            } if (item?.flagOffer) {
                mk.push(item)
            }
        });

        // console.log("filteredA---------rel", filteredArrays)
        setChangeCheck(filteredArrays)
        setCountTellAun(aun.length)
        setCountClose(close.length)
        setCountMk(mk.length)
        setCountTellAundata(aun)
        setCountClosedata(close)
        setCountMkdata(mk)
    };

    const handleToggleExpand = (key) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(key)
                ? prevExpandedRows.filter((rowKey) => rowKey !== key) // ถ้าแถวนี้ถูกขยายอยู่แล้ว, ให้ยุบ
                : [...prevExpandedRows, key] // ถ้ายังไม่ขยาย, ให้ขยาย
        );
    };
    const handleToggleExpandPay = (key) => {
        setExpandedRowsPay((prevExpandedRows) =>
            prevExpandedRows.includes(key)
                ? prevExpandedRows.filter((rowKey) => rowKey !== key) // ถ้าแถวนี้ถูกขยายอยู่แล้ว, ให้ยุบ
                : [...prevExpandedRows, key] // ถ้ายังไม่ขยาย, ให้ขยาย
        );
    };

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            width: "15%",
            render: (text, object, index) => {
                return (
                    <>
                        {
                            isMobile ?
                                <div
                                    data- label="ลำดับ : "
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "8px",
                                        height: "100%",
                                        color: 'white',
                                        width: '100%',
                                        background: "blue",
                                        borderRadius: '12px'
                                    }
                                    }
                                >
                                    <div>
                                        <span>ลำดับที่ : </span>
                                        {index + 1}
                                    </div>
                                </div >
                                :
                                <div data-label="ลำดับ : "

                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "8px",
                                        height: "100%",
                                    }}
                                >
                                    <div>
                                        {index + 1}
                                    </div>
                                </div>
                        }
                    </>
                )
            },
        },
        {
            title: "เลขสัญญา",
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: 'center',
            render: (text, record, index) => (
                <div data-label="เลขสัญญา : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.CONTNO || "ไม่มีเลขสัญญา"}
                </div>
            ),
        },
        {
            title: "เกรด",
            dataIndex: "GRDCOD",
            key: "GRDCOD",
            align: 'center',
            render: (text, record, index) => (
                <div data-label="เกรด : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    {record.GRDCOD || "ไม่มีเลขสัญญา"}
                </div>
            ),
        },
        {
            title: "ยอดที่จ่ายเข้า",
            dataIndex: "NETPAY",
            key: "NETPAY",
            align: 'center',
            render: (text, record, index) => (
                // console.log("record", record, text, index)
                <>
                    {
                        record?.dataList.length > 1 ?
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "8px",
                                        height: "100%",
                                        color: 'white',
                                        background: '#6b77ab',
                                        border: "2px solid #302e94f7", // เส้นกรอบรอบข้อมูล
                                        borderRadius: "8px", // เพิ่มมุมโค้งให้เส้นกรอบ
                                        margin: "8px 0", // ระยะห่างระหว่างแต่ละชุดข้อมูล
                                        boxShadow: "0 2px 4px rgba(29, 13, 102, 0.1)", // เพิ่มเงาให้ดูสวยงาม
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleToggleExpandPay(record.key)}
                                >
                                    <div>
                                        <span>จ่ายขั้นต่ำ:{" "}</span>
                                        {record.PAYLIMITAMT ? currencyFormatOne(record.PAYLIMITAMT) + ' ' + 'บาท' : '-'}
                                    </div>
                                    <div>
                                        <span>วันที่จ่าย:{" "}</span>
                                        {record.PAYDT ? dayjs(record.PAYDT).format("DD-MM-YYYY") : '-'}
                                    </div>
                                    <div>
                                        <span>ยอดที่จ่ายรวม:{" "}</span>
                                        <span
                                            style={{
                                                color: 'yellow',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {record.dataList.length > 1
                                                ? (() => {
                                                    // กรณี dataList มีมากกว่า 1
                                                    if (record.dataList.length > 1) {
                                                        // ตรวจสอบว่า NETPAY > PAYLIMITAMT ในแต่ละก้อน
                                                        const filteredData = record.dataList.filter(item => item.NETPAY);
                                                        if (filteredData.length > 1) {
                                                            // หากมีรายการที่ NETPAY > PAYLIMITAMT ให้รวม NETPAY ทั้งหมดใน dataList
                                                            return currencyFormatOne(
                                                                record.dataList.reduce((total, item) => total + (item.NETPAY || 0), 0)
                                                            ) + ' บาท';
                                                        } else {
                                                            return currencyFormatOne(record.NETPAY || 0) + ' บาท';
                                                        }
                                                    } else {
                                                        return currencyFormatOne(record.NETPAY || 0) + ' บาท';
                                                    }
                                                })()
                                                :
                                                <div>
                                                    <span>ยอดที่จ่าย:{" "}</span>
                                                    <span
                                                        style={{
                                                            color: 'red', // กำหนดสีตัวอักษรเป็นสีแดง
                                                            fontWeight: 'bold', // กำหนดตัวอักษรเป็นตัวหนา
                                                        }}
                                                    >
                                                        {record.NETPAY ? currencyFormatOne(record.NETPAY) + ' ' + 'บาท' : '-'}
                                                    </span>

                                                </div>
                                            }
                                        </span>
                                    </div>

                                    <span style={{ marginLeft: '10px' }}>
                                        {expandedRowsPay.includes(record.key) ? (
                                            <FaSortUp size={20} /> // แสดงลูกศรลงเมื่อขยาย
                                        ) : (
                                            <FaSortDown size={20} /> // แสดงลูกศรขวาเมื่อยุบ
                                        )}
                                    </span>

                                    {record.dataList.map((item, i) => {
                                        return (
                                            <React.Fragment key={`data-${record.key}-${i}`}> {/* เพิ่ม key */}
                                                {
                                                    expandedRowsPay.includes(record.key) && (
                                                        <>
                                                            <Divider style={{ margin: 3 }} />
                                                            <div
                                                                style={{
                                                                    marginTop: '10px',
                                                                    paddingLeft: '5px',
                                                                    backgroundColor: 'green',
                                                                    color: 'white',
                                                                    borderRadius: "8px",
                                                                    padding: '8px',
                                                                }}
                                                            >
                                                                <div>
                                                                    <span>จ่ายขั้นต่ำ:{" "}</span>
                                                                    {record.PAYLIMITAMT ? currencyFormatOne(record.PAYLIMITAMT) + ' ' + 'บาท' : '-'}
                                                                </div>
                                                                <div>
                                                                    <span>วันที่จ่าย:{" "}</span>
                                                                    {item.PAYDT ? dayjs(item.PAYDT).format("DD-MM-YYYY") : '-'}
                                                                </div>
                                                                <div>
                                                                    <span>ยอดที่จ่าย {i + 1} : {" "}</span>
                                                                    {item.NETPAY ? currencyFormatOne(item.NETPAY) + ' ' + 'บาท' : '-'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </>
                            :
                            <>
                                {record.dataList.map((item, i) => {
                                    return (
                                        <React.Fragment key={`data-${record.key}-${i}`}> {/* เพิ่ม key */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "8px",
                                                    height: "100%",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "8px",
                                                    margin: "8px 0",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                                                }}
                                            >

                                                <div>
                                                    <span>จ่ายขั้นต่ำ:{" "}</span>
                                                    {record.PAYLIMITAMT ? currencyFormatOne(record.PAYLIMITAMT) + ' ' + 'บาท' : '-'}
                                                </div>
                                                <div>
                                                    <span>วันที่จ่าย:{" "}</span>
                                                    {item.PAYDT ? dayjs(item.PAYDT).format("DD-MM-YYYY") : '-'}
                                                </div>
                                                <div>
                                                    <span>ยอดที่จ่าย:{" "}</span>
                                                    {item.NETPAY ? currencyFormatOne(item.NETPAY) + ' ' + 'บาท' : '-'}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </>
                    }
                </>
            ),
        },
        {
            title: "วันที่รับฝาก",
            dataIndex: "HDTEMPDATE",
            key: "HDTEMPDATE",
            align: 'center',
            render: (text, record, index) => (
                <>
                    {record.HDTEMPDATE && record.HDSUM ?
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "3px",
                                    height: "100%",
                                    border: "1px solid #ccc", // เส้นกรอบรอบข้อมูล
                                    borderRadius: "8px", // เพิ่มมุมโค้งให้เส้นกรอบ
                                    margin: "8px 0", // ระยะห่างระหว่างแต่ละชุดข้อมูล
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" // เพิ่มเงาให้ดูสวยงาม
                                }}
                                onClick={() => handleToggleExpand(record.key)}>
                                <div>
                                    <span>
                                        วันที่รับฝาก :
                                    </span>
                                    {record.TEMPDATE ? ' ' + dayjs(record.TEMPDATE).format("DD-MM-YYYY") : '-'}

                                </div>
                                <div>
                                    <span>ยอดรับฝาก : </span>
                                    <span
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        {/* จะเป็นสัญญาที่จ่ายมาแล้ว แต่เกินขั้นต่ำ ซึ่งส่วนเกินอาจจะโดนหักค่าติดตาม */}
                                        {record.resTOTAMT ? currencyFormatOne(record.resTOTAMT) + ' บาท' : '-'}
                                    </span>
                                </div>
                                <span style={{ marginLeft: '10px' }}>
                                    {expandedRows.includes(record.key) ? (
                                        <FaSortUp size={20} /> // แสดงลูกศรลงเมื่อขยาย
                                    ) : (
                                        <FaSortDown size={20} /> // แสดงลูกศรขวาเมื่อยุบ
                                    )}
                                </span>
                                {expandedRows.includes(record.key) && (
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            paddingLeft: '5px',
                                            backgroundColor: '#f9f9f9',
                                        }}
                                    >
                                        <div style={{ textAlign: 'start', paddingLeft: '3px' }}>
                                            <span>
                                                <u>รายการ</u>
                                            </span>
                                            {record?.NETPAY !== 0 || record?.resTOTAMT ?
                                                <>
                                                    <div style={{ textAlign: 'start', marginTop: '4px' }} key={index}>
                                                        ยอด : {record.resTOTAMT ? currencyFormatOne(record.resTOTAMT) + ' บาท' : '-'}
                                                    </div>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                        :
                        '-'
                    }
                </>
            ),
        },
        {
            title: "วันที่จ่าย",
            dataIndex: "PAYDT",
            key: "PAYDT",
            align: 'center',
            render: (text, record, index) => {
                // คำนวณวันที่จ่ายล่าสุดจาก dataList
                // const latestPayDate = record?.dataList
                //     ?.filter(item => item.PAYDT) // กรองเฉพาะรายการที่มี PAYDT
                //     .map(item => new Date(item.PAYDT)) // แปลงเป็น Date object
                //     .reduce((latest, current) => (current > latest ? current : latest), new Date(0)); // หา Date ที่ใหม่ที่สุด

                return (
                    <div data-label="วันที่จ่าย : "
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px",
                            height: "100%"
                        }}
                    >
                        {/* {record.NETPAY && record.NETPAY !== 0
                            ? dayjs(record.NETPAY).format("DD-MM-YYYY")
                            : '-'} */}
                        {record?.PAYDT
                            ? dayjs(record?.PAYDT).format("DD-MM-YYYY")
                            : '-'}
                    </div>
                );
            },
        },

    ];

    const paginationConfig = isMobile
        ? { position: ["bottomLeft"] } // ตั้งค่า pagination สำหรับมือถือ
        : null; // ปิด pagination ถ้าไม่ใช่มือถือ


    return (
        <Card style={{ height: '100%', }}>
            <Col span={24} style={{ textAlign: 'center' }}>
                <div className="text-center">
                    <h2>รับเข้าค่างวด</h2>
                </div>
            </Col>
            <Spin spinning={loading} size="large" tip="Loading..." >
                <div className="responsive-table">
                    <Row justify={'center'} style={{ marginBottom: 10 }}>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <span>เลือกบัญชี : </span>
                            <Select
                                style={{
                                    width: 280,
                                    height: '40px'
                                }}
                                value={selectedValueType}
                                onChange={onSelectType}
                            >
                                {countoptionsType()}
                            </Select>
                        </div>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <Button
                                style={{ marginRight: "10px", color: 'white', height: '40px', background: '#302e94f7' }}
                                onClick={onQueryContno}
                            >
                                <FaDatabase />
                                <b>ดึงข้อมูล</b>
                            </Button>
                        </div>
                        {checkLoadData === 0 ?
                            <>
                                <div style={{ margin: 2, textAlign: 'center' }}>
                                    <Button
                                        style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                        disabled
                                    >
                                        <FaDatabase />
                                        <b>Export Excel</b>
                                    </Button>
                                </div>
                                <div style={{ margin: 2, textAlign: 'center' }}>
                                    <Button
                                        style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                        disabled
                                    >
                                        <FaDatabase />
                                        <b>Export PDF</b>
                                    </Button>
                                </div>
                            </>
                            :
                            <>
                                <div style={{ margin: 2, textAlign: 'center' }}>
                                    <Button
                                        style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                        onClick={exportToExcel}
                                    >
                                        <FaDatabase />
                                        <b>Export Excel</b>
                                    </Button>
                                </div>
                                <div style={{ margin: 2, textAlign: 'center' }}>
                                    <Button
                                        style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                        onClick={exportToPDF}
                                    >
                                        <FaDatabase />
                                        <b>Export PDF</b>
                                    </Button>
                                </div>
                            </>
                        }
                    </Row>
                    <Row style={{ textAlign: 'center', width: '100%' }} justify={'center'} className='mains' align="middle">
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <span>เลือกประเภท : </span>
                            {checkLoadData === 0 ?
                                <>
                                    <Select
                                        disabled
                                        defaultValue={0}
                                        style={{
                                            width: 280,
                                            height: '40px'
                                        }}
                                        value={selectedValue}
                                    // onChange={onSelect}
                                    >
                                        {/* {countoptions()} */}
                                    </Select>
                                </>
                                :
                                <>
                                    <Select
                                        defaultValue={0}
                                        style={{
                                            width: 280,
                                            height: '40px'
                                        }}
                                        value={selectedValue}
                                        onChange={onSelect}
                                    >
                                        {countoptions()}
                                    </Select>
                                </>
                            }
                        </div>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            {checkLoadData === 0 ?
                                <>
                                    <Input
                                        disabled
                                        // value={queryContno}
                                        style={{ width: "280px", height: '40px' }}
                                        placeholder="ค้นหาเลขที่สัญญา"
                                        // onChange={handleInputChange}
                                        // onKeyDown={handleKeyDown}
                                        name="search"
                                        id="search"
                                    />
                                </>
                                :
                                <>
                                    <Input
                                        value={queryContno}
                                        style={{ width: "280px", height: '40px' }}
                                        placeholder="ค้นหาเลขที่สัญญา"
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        name="search"
                                        id="search"
                                    />
                                </>
                            }
                        </div>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <Button
                                style={{ marginRight: "5px", height: '40px' }}
                                onClick={onQuery}
                            >
                                <SearchOutlined />
                            </Button>
                        </div>
                        <div>
                            <Col span={24}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '8px',
                                        }}
                                    >
                                        <div style={{ gap: '8px', }}>
                                            <Checkbox
                                                onChange={(e) => handleChange("takebrotheraun", e.target.checked)}
                                                checked={dataforsearch.takebrotheraun} // แสดงสถานะปัจจุบัน
                                            >
                                                ไม่เอาเร่งรัดนอก ({countTellAun || 0})
                                            </Checkbox>

                                            <Checkbox
                                                onChange={(e) => handleChange("takethecasetoclose", e.target.checked)}
                                                checked={dataforsearch.takethecasetoclose} // แสดงสถานะปัจจุบัน
                                            >
                                                ไม่เอาเคสปิด ({countclose || 0})
                                            </Checkbox>

                                            <Checkbox
                                                onChange={(e) => handleChange("excludingseizedvehicles", e.target.checked)}
                                                checked={dataforsearch.excludingseizedvehicles} // แสดงสถานะปัจจุบัน
                                            >
                                                ไม่เอาสัญญาที่ส่งการตลาด ({countMk || 0})
                                            </Checkbox>

                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <Table
                            key={dataArray?.length}
                            style={{
                                marginTop: "5px",
                            }}
                            scroll={{
                                y: "600px",
                                // y: calculateTableHeight(),
                            }}
                            columns={columns}
                            dataSource={dataArray}
                            pagination={paginationConfig}
                        />
                    </Row>
                    {isMobile && showButton ? (
                        <Button
                            style={{
                                position: 'fixed',
                                bottom: '9%',
                                right: '6%',
                                zIndex: 1000,
                                backgroundColor: 'gray',
                                borderRadius: '50%',
                                width: '70px',
                                height: '70px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            <FaAngleDoubleUp size={60} color='white' />
                        </Button>
                    )
                        : null
                    }
                </div>
            </Spin>
        </Card>
    )
}

export default MainNew