import { Col, Form, Modal, Row, Space, Button, Divider, notification } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
// import { create_customer, outsidetoceo } from '../../mid/all-api';
import { outsidetoceo } from '../../mid/all-api';

function NoteSendtoCEO({ open, close, data, datainfo, dataSendtoCEO, status, onChangeStatused, handleCloseModal }) {
    const [form] = Form.useForm()
    const user = localStorage.getItem("username");
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newData, setNewData] = useState(datainfo ? { ...datainfo[0] } : '');
    const [api, contextHolder] = notification.useNotification();

    const [dataNote, setDataNote] = useState({
        noteBy: user,
        status: "1",
        noteDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    })


    const handleClose = () => {
        setIsModalOpen(false);
        close(false);
    };

    const handleGoBlack = () => {
        close(false);
    }

    const handleChangeNote = (e) => {
        setDataNote({ ...dataNote, note: e })
    }
    const successSend = (placement) => {
        api.success({
            message: <b>ส่งเคส {dataSendtoCEO?.CONTNO} สำเร็จ</b>,
            placement,
        });
    }
    const errorSend = (placement) => {
        api.error({
            message: <b>ส่งเคส {dataSendtoCEO?.CONTNO} ไม่สำเร็จ</b>,
            placement,
        });
    }

    const handleSubmit = async () => {
        // try {
        //     setLoading(true);
        //     const name = datainfo[0]?.CNAME
        //     console.log("name", name);
        //     // ใช้ regex แยกคำนำหน้า (นาย, นาง, นางสาว) ออกจากชื่อจริง-นามสกุล
        //     const nameParts = name.match(/^(นาย|นาง|นางสาว)\s*(\S+)\s*(\S+)$/);
        //     const snam = nameParts[1]; // คำนำหน้า (นาย, นาง, นางสาว)
        //     const firstname = nameParts[2]; // ชื่อจริง
        //     const lastname = nameParts[3]; // นามสกุล

        //     console.log("snam", snam);
        //     console.log("firstname", firstname);
        //     console.log("lastname", lastname);

        //     const paramCustomer = {
        //         customer: {
        //             customerId: 0,
        //             identificationId: datainfo[0]?.CUSCOD,
        //             snam: snam,
        //             firstname: firstname,
        //             lastname: lastname,
        //             nickname: "",
        //             nationalId: "",
        //             birthdate: "",
        //             gender: snam === "นาย" ? "M" : "W",
        //             issuby: "",
        //             issudt: "",
        //             expdt: "",
        //             idCard: "",
        //             description: ""
        //         },
        //         address: {
        //             customerId: 0,
        //             houseRegistrationNumber: "",
        //             houseNumber: datainfo[0]?.ADDRESS,
        //             soi: "",
        //             road: "",
        //             village: "",
        //             subdistrict: datainfo[0]?.TUMB,
        //             district: datainfo[0]?.AUMP,
        //             province: datainfo[0]?.PROVDES,
        //             zipCode: datainfo[0]?.ZIP,
        //             addressStatus: false,
        //             addressOnIdcard: true,
        //             houseRegistration: false,
        //             workAddress: false,
        //             otherAddress: false,
        //             currentAddress: false,
        //             sendDocuments: false
        //         },
        //         phone: {
        //             customerId: 0,
        //             phoneId: 0,
        //             phoneType: "เบอร์ติดต่อ",
        //             telp: datainfo[0]?.TELP,
        //             sendSms: ""
        //         }
        //     }


        //     console.log("paramCustomer", paramCustomer)

        //     // เรียก API แรกเพื่อสร้างลูกค้า
        //     const resCustomer = await axios.post(create_customer, paramCustomer);
        //     if (resCustomer.status !== 200 || !resCustomer.data) {
        //         throw new Error("Failed to create customer");
        //     }
        //     // ดึง customer_id จาก API ตอบกลับ
        //     const customerId = resCustomer.data.customer.customerId;
        //     console.log("customerId :", customerId);

        //     const param = {
        //         customer_id: customerId,
        //         BALANCE: dataSendtoCEO.BALANCE,
        //         CONTNO: dataSendtoCEO?.CONTNO,
        //         DATA_TYPE: dataSendtoCEO.DATA_TYPE,
        //         GRDCOD: dataSendtoCEO.GRDCOD,
        //         LPAYD: dataSendtoCEO.LPAYD ? dayjs(dataSendtoCEO.LPAYD).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
        //         NCARCST: dataSendtoCEO.NCARCST,
        //         SMPAY: dataSendtoCEO.SMPAY,
        //         TOT_UPAY: dataSendtoCEO.TOT_UPAY,
        //         EXP_AMT: dataSendtoCEO.EXP_AMT,
        //         TFDATE: dataSendtoCEO.TFDATE ? dayjs(dataSendtoCEO.TFDATE).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
        //         TOTPRC: dataSendtoCEO.TOTPRC.toString(),
        //         offerBy: user,
        //         proposalBy: dataSendtoCEO?.SALCOD, // แก้กลับเป็น data.SALCOD
        //         reviewedBy: dataSendtoCEO?.CHECKER, // แก้กลับเป็น data.CHECKER
        //         T_NOPAY: dataSendtoCEO.T_NOPAY,
        //         notesOutSidetoCEO: dataNote,
        //     }
        //     console.log("param", param)
        //     const resOutsidetoCEO = await axios.post(outsidetoceo, param);
        //     if (resOutsidetoCEO.status === 200 && resOutsidetoCEO.data) {
        //         successSend("top");
        //         onChangeStatused({ ...dataSendtoCEO, approvalStatus: 28 }, status);
        //         setTimeout(() => {
        //             handleCloseModal();
        //         }, 2000);
        //     }

        //     // await axios
        //     //     .post(`https://offercase-car-i67f6gaaqa-as.a.run.app/multi-car/create-customer`, paramCustomer)
        //     //     .then(async (res) => {
        //     //         if (res.data) {
        //     //             if (res.status === 200) {
        //     //                 successSend("top")
        //     //                 setTimeout(() => {
        //     //                     handleCloseModal()
        //     //                 }, 2000)
        //     //             }
        //     //         }
        //     //     })
        //     //     .catch((err) => {
        //     //         errorSend("top")
        //     //         console.log(err)
        //     //         setLoading(false);
        //     //     });

        //     // await axios
        //     //     .post(`http://localhost:8070/api-dets/outsidetoceo`, param)
        //     //     .then(async (resReq) => {
        //     //         if (resReq.data) {
        //     //             onChangeStatused({ ...dataSendtoCEO, approvalStatus: 28 }, status);
        //     //             if (resReq.status === 200) {
        //     //                 successSend("top")
        //     //                 setTimeout(() => {
        //     //                     handleCloseModal()
        //     //                 }, 2000)
        //     //             }
        //     //         }
        //     //     })
        //     //     .catch((err) => {
        //     //         errorSend("top")
        //     //         console.log(err)
        //     //         setLoading(false);
        //     //     });
        // } catch (error) {
        //     console.error("Error in API calls:", error);
        //     errorSend("top");
        //     setLoading(false);
        // }
    }

    return (
        <Modal
            title="ส่งชุดนอก"
            open={open}
            onCancel={handleClose}
            footer={[null]}
        >
            <Form
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    width: '100%',
                }}

                form={form}
                onFinish={handleSubmit}
                autoComplete="off"
                initialValues={{ remember: true }}
            >
                <Row justify={'center'}>
                    <Col span={22}>
                        <Form.Item
                            name="note"
                            label=""
                        >
                            <div>
                                <span><b><u>หมายเหตุ</u></b></span><p />
                                <TextArea
                                    name="note"
                                    placeholder='กรอกหมายเหตุ'
                                    style={{ color: 'blue' }}
                                    rows={8}
                                    onChange={(e) => {
                                        handleChangeNote(e.target.value);
                                    }}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Space>
                        <Button
                            type="text"
                            onClick={handleGoBlack}
                            style={{
                                background: 'red',
                                color: 'white',
                                height: '40px'
                            }} >
                            <b>ปิด</b>
                        </Button>
                        {
                            loading === false ?
                                <>
                                    <Button type="text" htmlType="submit" style={{ height: '40px', background: '#389e0d', color: '#ffffff' }} >
                                        <b>บันทึก</b>
                                    </Button>
                                </> :
                                <>
                                    <Button type='primary' htmlType="submit" disabled
                                        style={{ height: '40px', color: 'success' }}
                                    >
                                        <b>บันทึก</b>
                                    </Button>
                                </>
                        }
                    </Space>
                </Row>
            </Form>
            {contextHolder}
        </Modal >
    )
}

export default NoteSendtoCEO