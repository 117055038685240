import React, { useState } from 'react'
import { Card, Col, Input, Row, Spin, Table, Button } from 'antd';
import { SearchOutlined } from "@ant-design/icons";

function Main_check_info_inspector() {
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [checkLoadData, setCheckLoadData] = useState(0);
    const [queryContno, setQueryContno] = useState('');
    const [dataContno, setDataContno] = useState([]);
    const [arrayTable, setArrayTable] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const [scrollY, setScrollY] = useState(window.innerHeight);






    // ช่องกรอก
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        console.log("inputValue", inputValue);
        setQueryContno(inputValue);
        if (inputValue === '') {
            setArrayTable(dataContno)
        }
    };
    // ฟังก์ชันจับเหตุการณ์กด Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onQuery();  // เรียกฟังก์ชันค้นหาหลังจากกด Enter
        }
    };

    const onQuery = (value) => {
        console.log("value", value)
        if (!queryContno || queryContno.trim() === '') {
            setArrayTable(dataContno);
            return;
        }
        let lowerQuery = queryContno.toLowerCase();
        let result = dataContno.filter((item) =>
            (item.CONTNO && item.CONTNO.toLowerCase().includes(lowerQuery)) || // เลขสัญญา
            (item.NAME1 && item.NAME1.toLowerCase().includes(lowerQuery)) || // ชื่อ
            (item.NAME2 && item.NAME2.toLowerCase().includes(lowerQuery)) || // นามสกุล
            (item.REGNO && item.REGNO.toLowerCase().includes(lowerQuery)) || // ทะเบีย หรือ เลขโฉนด
            (item.STRNO && item.STRNO.toLowerCase().includes(lowerQuery))  //เลขคัสซี
        );
        // console.log("result", result)
        setArrayTable(result);
    };


    const calculateTableHeight = () => {
        const headerHeight = 100; // ความสูงของ Header
        const footerHeight = isMobile ? 150 : 0; // ความสูงของ Footer (มือถือมี Pagination)
        const marginHeight = 60; // Margin ระหว่างส่วนต่างๆ
        const otherComponentsHeight = 150; // ความสูงของส่วนอื่นๆ (ปุ่ม, Select, Input ฯลฯ)

        // console.log("headerHeight", headerHeight)
        // console.log("footerHeight", footerHeight)
        // console.log("marginHeight", marginHeight)
        // console.log("otherComponentsHeight", otherComponentsHeight)

        return scrollY - (headerHeight + footerHeight + marginHeight + otherComponentsHeight);
    };

    const columns = [
        {
            title: 'ลำดับ',
            dataIndex: 'key',
            key: 'key',
            render: (text, record, index) => {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: 'เลขสัญญา',
            dataIndex: 'CONTNO',
            key: 'CONTNO',
            render: (text, record) => {
                return (
                    <span>{record.CONTNO}</span>
                )
            }
        },
        {
            title: 'วันที่รับงาน',
            dataIndex: 'accptdate',
            key: 'accptdate',
            render: (text, record) => {
                return (
                    <span>{record.accptdate}</span>
                )
            }
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <span>{record.status}</span>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            console.log("record", record)
                        }}
                    >
                        ดูข้อมูล
                    </Button>
                )
            }
        },
    ];

    return (
        <Card
            style={{
                height: '100vh',
            }}
        >
            <Spin spinning={loading} size="large" tip="Loading..." >

                <Row gutter={32} justify={'center'}>
                    <Col>
                        <div className="text-center">
                            <h2>ตรวจสอบข้อมูลชุดนอก(ผู้ตรวจสอบ)</h2>
                        </div>
                    </Col>
                </Row>
                <Row
                    justify={'center'}
                    className="mains"
                    align="middle"
                    style={{ textAlign: 'center', width: '100%' }} // เพิ่มการจัดกึ่งกลาง
                >
                    <div style={{ margin: 2, textAlign: 'center' }}>
                        <span>กรอกเลขสัญญา : </span>
                        <Input
                            style={{ width: "280px", height: '40px' }}
                            placeholder="ค้นหาเลขที่สัญญา"
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            name="search"
                            id="search"
                        />

                    </div>
                    <div style={{ margin: 2, textAlign: 'center' }}>
                        <Button
                            style={{ marginRight: "10px", height: '40px', width: "50px" }}
                            onClick={(e) => onQuery(e)}
                        >
                            <SearchOutlined />
                        </Button>
                    </div>
                </Row>
                <Col
                    justify={'center'}
                    className="mains"
                    align="middle"
                    style={{ textAlign: 'center', width: '100%', marginTop: 5 }} // เพิ่มการจัดกึ่งกลาง
                >
                    <Table
                        key={arrayTable?.length}
                        className="responsive-table"
                        columns={columns}
                        dataSource={arrayTable}
                        scroll={{
                            y: calculateTableHeight(),
                        }}
                    // pagination={{ pageSize: 10 }}
                    />
                </Col>
            </Spin>
        </Card>
    )
}

export default Main_check_info_inspector