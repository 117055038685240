import React, { useState } from 'react';
import { Card, Col, Input, Row, Spin, Table, Button, Tag } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { render } from '@testing-library/react';
import { useEffect } from 'react';
import axios from 'axios';
import { results_contno } from '../mid/all-api';
import { FaCheckCircle, FaEdit, FaRegEnvelope, FaRegEnvelopeOpen } from 'react-icons/fa';
import BillExpediteSendOutside from './modals/BillExpediteSendOutside';

const MainCheckInfoOutside = () => {
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [checkLoadData, setCheckLoadData] = useState(0);
    const [queryContno, setQueryContno] = useState('');
    const [dataContno, setDataContno] = useState([]);
    const [arrayTable, setArrayTable] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const [scrollY, setScrollY] = useState(window.innerHeight);
    const [modalAddAaprove, setIsModalAddAaprove] = useState(false);
    const [datas, setDatas] = useState(false);
    const [dataArr, setDataArr] = useState([]);


    useEffect(() => {
        loadDataOutside();
    }, [])

    const loadDataOutside = async () => {
        setLoading(true)
        await axios.get(results_contno)
            .then((res) => {
                let i = 1;
                if (res.data) {
                    console.log("red.data", res.data)
                    const newData = res.data.map((item) => ({
                        ...item,
                        key: i++,
                    }));

                    setDataContno(newData)
                    setArrayTable(newData)
                    // setCheckLoadData(1)
                } else {
                    console.log("No red.data")
                }

            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const handleChangeStatus = (data, status) => {
        // setDataValue(status)
        console.log("TEST", data, status);
        const result = dataArr.map((item) => {
            if (item.CONTNO === data.CONTNO) {
                return { ...data };
            } else {
                return { ...item };
            }
        });
        setDataArr(result);
        // handleStatusApproval(result);
        if (status === "reset") {
            setArrayTable(result);
        } else {
            const arr = result.filter((item) =>
                item.ApprovalStatus === status
            );
            setArrayTable(arr);

        }
    };
    const handleCloseModal = () => {
        setIsModalAddAaprove(false);
        loadDataOutside()
    };

    // ช่องกรอก
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        console.log("inputValue", inputValue);
        setQueryContno(inputValue);
        if (inputValue === '') {
            setArrayTable(dataContno)
        }
    };
    // ฟังก์ชันจับเหตุการณ์กด Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onQuery();  // เรียกฟังก์ชันค้นหาหลังจากกด Enter
        }
    };

    const onQuery = (value) => {
        console.log("value", value)
        if (!queryContno || queryContno.trim() === '') {
            setArrayTable(dataContno);
            return;
        }
        let lowerQuery = queryContno.toLowerCase();
        let result = dataContno.filter((item) =>
            (item.CONTNO && item.CONTNO.toLowerCase().includes(lowerQuery)) || // เลขสัญญา
            (item.NAME1 && item.NAME1.toLowerCase().includes(lowerQuery)) || // ชื่อ
            (item.NAME2 && item.NAME2.toLowerCase().includes(lowerQuery)) || // นามสกุล
            (item.REGNO && item.REGNO.toLowerCase().includes(lowerQuery)) || // ทะเบีย หรือ เลขโฉนด
            (item.STRNO && item.STRNO.toLowerCase().includes(lowerQuery))  //เลขคัสซี
        );
        // console.log("result", result)
        setArrayTable(result);
    };


    const calculateTableHeight = () => {
        const headerHeight = 100; // ความสูงของ Header
        const footerHeight = isMobile ? 150 : 0; // ความสูงของ Footer (มือถือมี Pagination)
        const marginHeight = 60; // Margin ระหว่างส่วนต่างๆ
        const otherComponentsHeight = 150; // ความสูงของส่วนอื่นๆ (ปุ่ม, Select, Input ฯลฯ)

        // console.log("headerHeight", headerHeight)
        // console.log("footerHeight", footerHeight)
        // console.log("marginHeight", marginHeight)
        // console.log("otherComponentsHeight", otherComponentsHeight)

        return scrollY - (headerHeight + footerHeight + marginHeight + otherComponentsHeight);
    };

    const columns = [
        {
            title: 'ลำดับ',
            dataIndex: 'key',
            key: 'key',
            render: (text, record, index) => {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: 'เลขสัญญา',
            dataIndex: 'CONTNO',
            key: 'CONTNO',
            render: (text, record) => {
                return (
                    <span>{record.CONTNO}</span>
                )
            }
        },
        {
            title: 'วันที่รับงาน',
            dataIndex: 'accptdate',
            key: 'accptdate',
            render: (text, record) => {
                return (
                    <span>{record.accptdate}</span>
                )
            }
        },
        {
            title: 'สถานะ',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            render: (text, record) => {
                return (
                    <>
                        {record.approvalStatus === 28 ?
                            <Tag style={{ background: '#f2b335', color: 'black', width: '90px', textAlign: 'center' }}>
                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <FaRegEnvelope size={12} style={{ color: 'black', marginRight: '10px' }} />
                                    {record.approvalStatusName}
                                </span>
                            </Tag>
                            : record.approvalStatus === 2 ?
                                <Tag style={{ background: 'gray', color: 'white', width: '90px', textAlign: 'center' }}>
                                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <FaRegEnvelopeOpen size={12} style={{ color: 'white', marginRight: '10px' }} />
                                        {record.approvalStatusName}
                                    </span>
                                </Tag>
                                : record.approvalStatus === 3 &&
                                <Tag style={{ background: 'green', color: 'white', width: '90px', textAlign: 'center' }}>
                                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <FaCheckCircle size={12} style={{ color: 'white', marginRight: '10px' }} />
                                        {record.approvalStatusName}
                                    </span>
                                </Tag>
                        }
                    </>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render: (text, record) => {
                return (
                    <>
                        {
                            record?.approvalStatus === 3 ?
                                <>
                                    <Button
                                        style={{ height: '40px', background: 'gray', color: 'white  ' }}
                                        onClick={() => {
                                            setIsModalAddAaprove(true);
                                            setDatas(record);

                                        }}
                                    >
                                        <b>
                                            รอเสนอชุดนอก
                                        </b>
                                    </Button>
                                </>
                                :
                                null
                        }
                    </>
                )
            }
        },
    ];

    return (
        <Card
            style={{
                height: '100vh',
            }}
        >
            <Spin spinning={loading} size="large" tip="Loading..." >

                <Row gutter={32} justify={'center'}>
                    <Col>
                        <div className="text-center">
                            <h2>ตรวจสอบข้อมูลชุดนอก(MK)</h2>
                        </div>
                    </Col>
                </Row>
                <Row
                    justify={'center'}
                    className="mains"
                    align="middle"
                    style={{ textAlign: 'center', width: '100%' }} // เพิ่มการจัดกึ่งกลาง
                >
                    <div style={{ margin: 2, textAlign: 'center' }}>
                        <span>กรอกเลขสัญญา : </span>
                        <Input
                            style={{ width: "280px", height: '40px' }}
                            placeholder="ค้นหาเลขที่สัญญา"
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            name="search"
                            id="search"
                        />

                    </div>
                    <div style={{ margin: 2, textAlign: 'center' }}>
                        <Button
                            style={{ marginRight: "10px", height: '40px', width: "50px" }}
                            onClick={(e) => onQuery(e)}
                        >
                            <SearchOutlined />
                        </Button>
                    </div>
                </Row>
                <Col
                    justify={'center'}
                    className="mains"
                    align="middle"
                    style={{ textAlign: 'center', width: '100%', marginTop: 5 }} // เพิ่มการจัดกึ่งกลาง
                >
                    <Table
                        key={arrayTable?.length}
                        className="responsive-table"
                        columns={columns}
                        dataSource={arrayTable}
                        scroll={{
                            y: calculateTableHeight(),
                        }}
                    // pagination={{ pageSize: 10 }}
                    />
                </Col>
            </Spin>
            {
                modalAddAaprove ?
                    <BillExpediteSendOutside
                        open={modalAddAaprove}
                        close={setIsModalAddAaprove}
                        continoBill={datas.CONTNO}
                        data={datas}
                        dataOutside={dataContno}
                        onChangeStatus={handleChangeStatus}
                        handleCloseModal={handleCloseModal}
                    />
                    : null
            }
        </Card>

    );
};

export default MainCheckInfoOutside;