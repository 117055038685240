import React, { useState, useMemo } from 'react';
import {
	FaAngleRight, FaAngleLeft, FaSignOutAlt, FaUsers, FaAngleDown, FaHouseUser, FaCar,
	FaUserSecret, FaWallet, FaIdCardAlt, FaCalendarTimes, FaUser, FaPrint, FaHandshake,
	FaTelegramPlane, FaFile,
	FaShoppingCart,
	FaSearch,
} from 'react-icons/fa';
import { AiTwotonePhone } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { Image } from 'antd';
import "./navbar.css";

const ICON_SIZE = 20;

function Navbar({ visible, show, onLogout }) {
	const [subMenuOpen, setSubMenuOpen] = useState({}); // ใช้ object สำหรับจัดการหลาย SubMenu

	// ดึงข้อมูลจาก localStorage
	const { username, idDepartment, roleId } = useMemo(() => ({
		username: localStorage.getItem('username'),
		idDepartment: localStorage.getItem('idDepartment'),
		roleId: localStorage.getItem('roleId'),
	}), []);

	const toggleSubMenu = (menuKey) => {
		setSubMenuOpen((prev) => ({
			...prev,
			[menuKey]: !prev[menuKey],
		}));
	};

	const handleLogout = () => {
		localStorage.clear();
		onLogout();
	};

	// ฟังก์ชันแยกสำหรับ SubMenu
	const renderSubMenu = (menuKey, items) => {
		return (
			<>
				<div onClick={() => toggleSubMenu(menuKey)}>
					<h4>
						<div className="nav-link" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{(menuKey === "เร่งรัด-หนี้สิน" || menuKey === "เร่งรัด-ภาคสนาม") && <FaHandshake size={ICON_SIZE} />}
								{menuKey === "รายงาน" && <FaPrint size={ICON_SIZE} />}
								{menuKey === "ชุดนอก" && <FaFile size={ICON_SIZE} />}
								<span style={{ marginLeft: 8 }}>{menuKey}</span>
							</div>
							<FaAngleDown size={ICON_SIZE} style={{ transform: subMenuOpen[menuKey] ? 'rotate(180deg)' : 'rotate(0)' }} />
						</div>
					</h4>
					{subMenuOpen[menuKey] && (
						<div style={{ paddingLeft: '20px' }}>
							{items.map(({ to, icon, label }) => (
								<NavLink to={to} key={to} className="nav-link" onClick={(e) => e.stopPropagation()}>
									{icon}
									<span>{label}</span>
								</NavLink>
							))}
						</div>
					)}
				</div>
			</>
		)
	};

	return (
		<nav className={!visible ? 'navbar' : ''}>
			<div className='scollheight'>
				<button
					type="button"
					className="nav-btn"
					onClick={() => show(!visible)}
				>
					{!visible ? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
				</button>
				<div style={{ marginTop: '8px' }}>
					<div className="logo">
						<NavLink to="/">
							<Image
								src={require("../../assets/Images/logo-yellow.png")}
								alt="logo"
								preview={false}
								style={{ color: 'white', border: 'none', borderRadius: '8px' }}
							/>
						</NavLink>
					</div>

					<div className="links nav-top">
						<NavLink className="nav-link">
							<FaUser size={ICON_SIZE} />
							<div className='center'>
								<span><b>{username}</b></span>
							</div>
						</NavLink>
						<NavLink to="/" className="nav-link">
							<FaHouseUser size={ICON_SIZE} />
							<span><b>หน้าหลัก</b></span>
						</NavLink>
						{(idDepartment === '1' || roleId === '39') && (
							<NavLink to="/operator" className="nav-link">
								<AiTwotonePhone size={ICON_SIZE} style={{ transform: 'rotate(90deg)' }} />
								<span><b>โอเปอร์เตอร์</b></span>
							</NavLink>
						)}
						{(idDepartment === '1' || roleId === '40') && (
							<NavLink to="/collector" className="nav-link">
								<FaShoppingCart size={ICON_SIZE} />
								<span><b>Collector</b></span>
							</NavLink>
						)}

						{/* SubMenu สำหรับเร่งรัด-หนี้สิน */}
						{(idDepartment === '1' || roleId === '30') && renderSubMenu('เร่งรัด-หนี้สิน', [
							{ to: "/car", icon: <FaCar size={ICON_SIZE} />, label: "รถ" },
							{ to: "/follow_case", icon: <FaUsers size={ICON_SIZE} />, label: "ติดตามเคส" },
							{ to: "/suspensioncase", icon: <FaCalendarTimes size={ICON_SIZE} />, label: "เคสที่โดนระงับ" },
						])}

						{/* SubMenu สำหรับเร่งรัด-ภาคสนาม */}
						{(idDepartment === '1' || roleId === '37') && renderSubMenu('เร่งรัด-ภาคสนาม', [
							{ to: "/search", icon: <FaSearch size={ICON_SIZE} />, label: "ค้นหา" },
							{ to: "/cars_expedite", icon: <FaIdCardAlt size={ICON_SIZE} />, label: "พอร์ต" },
							{ to: "/contno_detaile", icon: <FaWallet size={ICON_SIZE} />, label: "รับเข้าค่างวด" },
							{ to: "/pay", icon: <FaUserSecret size={ICON_SIZE} />, label: "Treller" },
						])}

						{/* SubMenu สำหรับรายงานพี่แพรว */}
						{(idDepartment === '1' || roleId === '41') && renderSubMenu('รายงาน', [
							{ to: "/call_team_status", icon: <FaIdCardAlt size={ICON_SIZE} />, label: "ประมวลผล" },
							{ to: "/report_all", icon: <FaWallet size={ICON_SIZE} />, label: "รายงาน" },
						])}

						{/* SubMenu สำหรับชุดนอก */}
						{/* {(idDepartment === '1' || roleId === '37') && renderSubMenu('ชุดนอก', [
							{ to: "/send_outside", icon: <FaTelegramPlane size={ICON_SIZE} />, label: "ส่งชุดนอก" },
							{ to: "/outside_ceo", icon: <FaIdCardAlt size={ICON_SIZE} />, label: "ตรวจสอบชุดนอก(CEO)" },
							{ to: "/outside_mk", icon: <FaWallet size={ICON_SIZE} />, label: "ตรวจสอบชุดนอก(MK)" },
							{ to: "/outside_checker", icon: <FaUserSecret size={ICON_SIZE} />, label: "ผู้ตรวจสอบชุดนอก(แม่น้ำ)" },
						])} */}

						{/* {(idDepartment === '1' || roleId === '41') && (
							<NavLink to="/call_team_status" className="nav-link">
								<FaPrint size={ICON_SIZE} />
								<span><b>พิมพ์รายงาน</b></span>
							</NavLink>
						)} */}
						{/* {(idDepartment === '1' || roleId === '37') && (
							<NavLink to="/outside_ceo" className="nav-link">
								<FaFile size={ICON_SIZE} />
								<span><b>ตรวจสอบชุดนอก(CEO)</b></span>
							</NavLink>
						)}
						{(idDepartment === '1' || roleId === '37') && (
							<NavLink to="/outside_mk" className="nav-link">
								<FaFile size={ICON_SIZE} />
								<span><b>ตรวจสอบชุดนอก(MK)</b></span>
							</NavLink>
						)}
						{(idDepartment === '1' || roleId === '37') && (
							<NavLink to="/outside_checker" className="nav-link">
								<FaFile size={ICON_SIZE} />
								<span><b>ผู้ตรวจสอบชุดนอก(แม่น้ำ)</b></span>
							</NavLink>
						)} */}
						{/* {(idDepartment === '1') && (
							<NavLink to="/report_all" className="nav-link">
								<FaFile size={ICON_SIZE} />
								<span><b>รายงาน</b></span>
							</NavLink>
						)} */}

					</div>

					{/* Logout */}
					<div className="logout-container">
						<NavLink to="/" className="nav-link" onClick={handleLogout}>
							<FaSignOutAlt size={ICON_SIZE} />
							<span><b>ออกจากระบบ</b></span>
						</NavLink>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;

