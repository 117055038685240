import React, { useState, useEffect, useRef } from "react";
import { Form, Card, Row, Col, Spin, Select, Input, Table, Divider, Button, Space, Flex, Radio } from 'antd';
import { DatabaseOutlined, HomeFilled, PhoneOutlined, SendOutlined } from '@ant-design/icons';
import Contract_Details from "./Contract_Details";
import Payment_Schedule from "./payment_schedule/Payment_Schedule";
import Other_Debtor_Details from "./Other_Debtor_Details";

const MainNewPage_Operators = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [radioselect, setRadioSelect] = useState('1')

    const onChange = (e) => {
        console.log(`radio checked:${e.target.value}`);
        const value = e.target.value
        setRadioSelect(value)
    };

    return (
        <>
            <div style={{ height: '100vh', overflow: 'auto' }}>
                <Form
                    form={form}
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Row
                            style={{
                                textAlign: 'center',
                                position: 'fixed',   // ตรึงไว้ด้านบน
                                top: '0',            // วางไว้ที่ด้านบนสุด
                                width: '100%',       // ขยายเต็มหน้าจอ
                                backgroundColor: '#fff',  // เพิ่มพื้นหลังเพื่อให้ดูแยกจากเนื้อหาข้างล่าง
                                zIndex: 100,           // เพื่อให้หัวข้อนี้อยู่ข้างหน้าของเนื้อหาอื่น
                            }}
                        >
                            <Col span={24} >
                                <div style={{ fontSize: '20px', margin: '10px 0px 0px 20px' }}>
                                    <b><u>สอบถามลูกหนี้</u></b>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ padding: '50px 15px 15px 15px', backgroundColor: '#ffffff' }}>
                            <Card style={{ marginBottom: 20, width: '100%', backgroundColor: '#f0f0f0' }}>
                                <Col span={24}>
                                    <Row>
                                        <Form.Item
                                            label='สถานะลูกหนี้' name='debtor_status'
                                        >
                                            <Input
                                                style={{ width: '200px', marginRight: 3 }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='เกรดสัญญา' name='contract_grade'
                                        >
                                            <Input
                                                style={{ width: '200px', marginRight: 3 }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='เลขสัญญา' name='contract_number'
                                        >
                                            <Input
                                                style={{ width: '200px', marginRight: 3 }}
                                            />
                                        </Form.Item>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Divider orientation="center" style={{ fontSize: '20px', margin: 5 }}>
                                        <b><u>ข้อมูลสัญญา</u></b>
                                    </Divider>
                                    <Row>
                                        <Form.Item
                                            label='เลขสัญญา' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='ชื่อลูกค้า' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='วันทำสัญญา' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='วันชำระล่าสุด' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='ค่างวดงวดละ' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='ยอดค้างชำระ' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='ค้าง/งวด' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='ค้างจากงวดที่' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='ถึงงวดที่' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='สัญญาสาขา' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='ค้างจริง' name='contract_number'
                                        >
                                            <Input
                                            // style={{ width: '200px',  }}
                                            />
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Card>
                            <Card style={{ width: '100%', backgroundColor: '#f0f0f0' }}>
                                <Col span={24}>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Flex vertical gap="middle">
                                            <Radio.Group onChange={onChange} defaultValue="1">
                                                <Radio.Button value="1">รายละเอียดสัญญา</Radio.Button>
                                                <Radio.Button value="2">ตารางการชำระ</Radio.Button>
                                                <Radio.Button value="3">รายละเอียดลูกหนี้อื่น</Radio.Button>
                                            </Radio.Group>
                                        </Flex>

                                    </Row>
                                    <Row>
                                        {radioselect === '1' && (<Contract_Details />)}
                                        {radioselect === '2' && (<Payment_Schedule />)}
                                        {radioselect === '3' && (<Other_Debtor_Details />)}
                                    </Row>
                                </Col>
                            </Card>
                        </Row>
                        <Row span={24} style={{ textAlign: 'center' }}                        >
                            <Col span={24}>
                                <Button
                                    href="/"
                                    type="primary"
                                    style={{
                                        margin: 15,
                                        textAlign: 'center',
                                        // position: 'fixed',
                                        // bottom: '20px',
                                    }}><HomeFilled />หน้าหลัก</Button>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </div >
        </>
    )
}
export default MainNewPage_Operators;
